import React, { useEffect } from "react"
import { useHistory } from "react-router"
import { FaAngleRight } from "react-icons/fa"

export const AssessmentsListItem = ({ assessment }) => {
  const history = useHistory()

  return (
    <div className="p-3 border-b-2 border-gray-500 md:border md:rounded-lg md:mb-4">
      <div className="float-right">
        <div className="text-4xl p-1 rounded-full bg-primary text-white mt-1">
          <FaAngleRight
            className="md:cursor-pointer"
            onClick={() => history.push(`/assessment/${assessment.unique_id}`)}
          />
        </div>
      </div>
      <div className="text-2xl font-semibold">{assessment.subject.name}</div>
      <div className="text-gray-500">
        {assessment.authorizer.name} <br />
        {assessment.subject.business_name ? (
          <>
            {" "}
            {assessment.subject.business_name} <br />{" "}
          </>
        ) : null}
        {assessment.subject.postcode} <br />
      </div>
    </div>
  )
}
