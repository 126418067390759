import React, { useEffect, useState } from "react"
import { MdAdd, MdRemove } from "react-icons/md"
import { QuestionStatus } from "../saveQuestion"

export const ListQuestion = (props) => {
  const [answer, setAnswer] = useState(props.answer || [""])
  const [saved, setSaved] = useState(false)
  const [valid, setValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [saveButtonText, setSaveButtonText] = useState("Save")
  const [saveButtonColour, setSaveButtonColour] = useState("bg-primary")

  useEffect(() => {
    if (props.answer) setSaved(true)
  }, [])

  const updateAnswer = (index, value) => {
    const answers = [...answer]
    answers[index] = value
    setAnswer(answers)
    setSaved(false)
  }

  const saveAnswer = () => {
    let invalidated = answer.filter((a) => a === "")

    if (invalidated.length > 0) {
      setValid(false)
      setErrorMessage("Answer can't be blank.")
      setSaveButtonColour("bg-red-600")
      setSaveButtonText("Error!")

      props.removeFromAnswers()
      setSaved(false)
    } else {
      setValid(true)
      setErrorMessage(null)
      setSaveButtonColour("bg-green-600")
      setSaveButtonText("Saved!")

      props.saveToAnswers(answer)
      setSaved(true)
    }

    setTimeout(() => {
      setSaveButtonColour("bg-primary")
      setSaveButtonText("Save")
    }, 1000)
  }

  const addTextInput = () => {
    const answers = [...answer]
    answers.push("")
    setAnswer(answers)
    setSaved(false)
  }

  const removeTextInput = (index) => {
    const answers = [...answer]
    answers.splice(index, 1)
    setAnswer(answers)
    setSaved(false)
  }

  return (
    <div>
      {Array.isArray(answer) &&
        answer.map((a, i) => (
          <div key={i} className="mb-1">
            <input
              type="text"
              placeholder="Type your answer..."
              className={`w-5/6 outline-none border-2 border-gray-300 focus:border-2 focus:border-yellow-500 focus:rounded rounded-lg p-2 text-left`}
              value={answer[i]}
              autoComplete="off"
              onChange={(e) => updateAnswer(i, e.target.value)}
            />
            <button
              type="button"
              className={`rounded-lg p-2 px-3 ml-2 bg-gray-200 text-lg font-semibold ${
                i < 1 && "invisible"
              }`}
              onClick={() => removeTextInput(i)}
            >
              <MdRemove className="inline" />
            </button>
          </div>
        ))}
      <div className="text-left ml-1">
        <button
          type="button"
          className="rounded-lg p-2 px-3 mt-2 bg-gray-200 text-lg font-semibold"
          onClick={() => addTextInput()}
        >
          <MdAdd className="inline" />
        </button>
      </div>
      <QuestionStatus
        question={props.question}
        answer={answer}
        saveAnswer={saveAnswer}
        saved={saved}
        saveButtonColour={saveButtonColour}
        setSaveButtonColour={setSaveButtonColour}
        saveButtonText={saveButtonText}
        setSaveButtonText={setSaveButtonText}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        comment={props.comment}
        saveComment={props.saveComment}
        conformance={props.conformance}
        saveConformance={props.saveConformance}
      />
    </div>
  )
}
