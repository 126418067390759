import React, { useEffect, useRef, useState } from "react"
import { QuestionStatus } from "../saveQuestion"

export const BooleanQuestion = (props) => {
  const [answer, setAnswer] = useState(props.answer || "")
  const [saved, setSaved] = useState(false)
  const [valid, setValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [saveButtonText, setSaveButtonText] = useState("Save")
  const [saveButtonColour, setSaveButtonColour] = useState("bg-primary")

  useEffect(() => {
    if (props.answer) setSaved(true)
  }, [])

  const updateAnswer = (value) => {
    if (answer === value) {
      setAnswer("")
    } else {
      setAnswer(value)
      setSaved(false)
    }
  }

  const saveAnswer = () => {
    if (answer === "") {
      setValid(false)
      setErrorMessage("You must select an answer.")
      setSaveButtonColour("bg-red-600")
      setSaveButtonText("Error!")

      props.removeFromAnswers()
      setSaved(false)
    } else {
      setValid(true)
      setErrorMessage(null)
      setSaveButtonColour("bg-green-600")
      setSaveButtonText("Saved!")
      props.saveToAnswers(answer)
      setSaved(true)
    }

    setTimeout(() => {
      setSaveButtonColour("bg-primary")
      setSaveButtonText("Save")
    }, 1000)
  }

  return (
    <div>
      <div className="inline-flex rounded-md shadow-sm" role="group">
        <button
          type="button"
          onClick={() => updateAnswer("yes")}
          className={`${
            answer === "yes" ? "bg-gray-400" : "hover:bg-gray-300"
          } py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-l-lg border border-gray-200`}
        >
          Yes
        </button>
        <button
          type="button"
          onClick={() => updateAnswer("no")}
          className={`${
            answer === "no" ? "bg-gray-400" : "hover:bg-gray-300"
          } py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-r-md border border-gray-200`}
        >
          No
        </button>
      </div>

      <QuestionStatus
        question={props.question}
        answer={answer}
        saveAnswer={saveAnswer}
        saved={saved}
        saveButtonColour={saveButtonColour}
        setSaveButtonColour={setSaveButtonColour}
        saveButtonText={saveButtonText}
        setSaveButtonText={setSaveButtonText}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        comment={props.comment}
        saveComment={props.saveComment}
        conformance={props.conformance}
        saveConformance={props.saveConformance}
      />
    </div>
  )
}
