import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useStore } from "react-context-hook"
import { NavBar } from "../components/navbar"
import { languages } from "../../config/languages"

export const Home = () => {
  const [translationIds, setTranslationIds] = useStore("translationIds")
  const [account, setAccount] = useStore("account", null)
  const [assessments, setAssessments] = useStore("assessments", [])
  const [captureGroups, setCaptureGroups] = useStore("captureGroups", [])
  const [sortedLanguages, setSortedLanguages] = useState(null)

  const [appConfig, setAppConfig] = useStore("appConfig", null)

  const history = useHistory()

  useEffect(() => {
    const _sortedLanguages = sortLanguages(getSpecificTenantLanguages())
    setSortedLanguages(_sortedLanguages)
  }, [])

  const getSpecificTenantLanguages = () => {
    const lang = {}
    translationIds.forEach((id) => {
      lang[id] = languages[id]
    })
    return lang
  }

  const sortLanguages = (languages) => {
    return Object.keys(languages).sort((a, b) => {
      let aName = languages[a].name.toUpperCase()
      let bName = languages[b].name.toUpperCase()
      if (aName < bName) {
        return -1
      }
      if (aName > bName) {
        return 1
      }
      return 0
    })
  }

  return (
    <>
      <NavBar
        text={`Hello ${account.firstname}`}
        iconLeft="refresh"
        iconRight="account"
      />
      <div className="md:pt-10 pb-15 flex flex-col md:gap-2">
        {!appConfig || appConfig?.enableAssessments ? (
          <div className="flex flex-row w-full md:w-1/2 xl:w-1/3 mx-auto text-3xl font-bold text-gray-600 border-gray-500 border-b-2 md:border-2 md:rounded-xl">
            <p className="text-primary text-6xl p-2 px-3">
              {assessments.length}
            </p>
            <p className="align-middle my-auto">Assessments Pending</p>
            <button
              className="md:rounded-lg md:rounded-l-none ml-auto p-1 px-6 text-white text-3xl font-semibold bg-primary"
              onClick={(e) => history.push("/assessments")}
            >
              Start
            </button>
          </div>
        ) : null}
        {!appConfig || appConfig?.enableCaptureGroups ? (
          <div className="flex flex-row w-full md:w-1/2 xl:w-1/3 mx-auto text-3xl font-bold text-gray-600 border-gray-500 border-b-2 md:border-2 md:rounded-xl">
            <p className="text-primary text-6xl p-2 px-3">
              {captureGroups.length}
            </p>
            <p className="align-middle my-auto">Farms Active</p>
            <button
              className="md:rounded-lg md:rounded-l-none z-0 ml-auto p-1 px-6 text-white text-3xl font-semibold bg-primary"
              onClick={(e) => history.push("/capture-groups")}
            >
              Start
            </button>
          </div>
        ) : null}
      </div>
    </>
  )
}
