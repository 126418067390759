import Dexie from "dexie"

export const db = new Dexie("files")
db.version(1).stores(
  { items: "unique_id,survey_id,name,size,type" } // NOTE we are not indexing data prop as it is the image, oly need to index if using for wheres
)

export const addItemToDb = async (item, survey, section, question) => {
  return db.items.add({
    ...item,
    survey_id: survey,
    section,
    question,
  })
}

export const removeItemFromDb = async (unique_id) => {
  const res = await db.items.delete(unique_id)
}
