import { useState, useEffect } from "react"

export const useOnlineStatus = () => {
  const [online, setOnline] = useState(window.navigator.onLine)

  const updateStatus = () => {
    setOnline(window.navigator.onLine)
  }

  useEffect(() => {
    window.addEventListener("online", updateStatus)
    window.addEventListener("offline", updateStatus)
    return () => {
      window.removeEventListener("online", updateStatus)
      window.removeEventListener("offline", updateStatus)
    }
  })

  return online
}
