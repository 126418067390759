import React from "react"
import { useHistory } from "react-router-dom"
import { FaAngleLeft } from "react-icons/fa"

export const BackIcon = (props) => {
  const history = useHistory()

  return (
    <div className="w-10 h-full pt-1">
      <FaAngleLeft
        className="text-4xl mx-auto md:cursor-pointer"
        onClick={(e) => history.push(props.route)}
      />
    </div>
  )
}
