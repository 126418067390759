import React, { useEffect, useState } from "react"
import { useStore } from "react-context-hook"
import { QuestionStatus } from "../saveQuestion"

export const FormulaQuestion = (props) => {
  const [answer, setAnswer] = useState("")
  const [answers, setAnswers] = useStore("answers", {})
  const [saved, setSaved] = useState(false)
  const [valid, setValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [saveButtonText, setSaveButtonText] = useState("Save")
  const [saveButtonColour, setSaveButtonColour] = useState("bg-primary")

  useEffect(() => {
    getAnswer()
  }, [answers])

  const getAnswer = () => {
    const thisAnswer = props.getFromAnswers()
    if (thisAnswer) setSaved(true)
    const { unit, formula, decimals } = props.question.properties
    let calculatedAnswer = formula

    if (answers.hasOwnProperty(props.params.surveyId)) {
      let questionsIdentified = 0
      let questionsAnswered = 0
      let questionsNumbers = 0
      let currentAnswer = false
      const questionsCopy = [
        ...answers[props.params.surveyId].schema.sections[
          props.params.sectionId - 1
        ].questions,
      ]

      questionsCopy.forEach((question) => {
        if (formula.includes("Q" + question.position)) {
          questionsIdentified++
          if (question.hasOwnProperty("answer")) {
            currentAnswer = getAnswerFromQuestion(question)
            questionsAnswered++
            if (+currentAnswer === +currentAnswer) {
              questionsNumbers++
              calculatedAnswer = calculatedAnswer.replace(
                "Q" + question.position,
                currentAnswer
              )
            }
          }
        }
      })

      if (questionsIdentified === questionsNumbers) {
        calculatedAnswer = eval(calculatedAnswer)
        if (decimals) calculatedAnswer = calculatedAnswer.toFixed(decimals)
        setValid(true)
        setErrorMessage(null)
      } else {
        calculatedAnswer = ""
      }

      if (
        questionsIdentified === questionsAnswered &&
        questionsAnswered !== questionsNumbers
      ) {
        setValid(false)
        setErrorMessage("Can't calculate from text, only numbers.")
      }

      if (thisAnswer && calculatedAnswer != thisAnswer) {
        props.removeFromAnswers()
        setSaved(false)
      }

      setAnswer(calculatedAnswer)
    }
  }

  const getAnswerFromQuestion = (question) => {
    switch (question.type) {
      case "text":
        return question.answer
      case "number":
        return question.answer.number
      default:
        return question.answer
    }
  }

  const saveAnswer = () => {
    if (answer === "") {
      setValid(false)
      setErrorMessage("You must enter an answer.")
      setSaveButtonColour("bg-red-600")
      setSaveButtonText("Error!")

      props.removeFromAnswers()
      setSaved(false)
    } else {
      setValid(true)
      setErrorMessage(null)
      setSaveButtonColour("bg-green-600")
      setSaveButtonText("Saved!")

      props.saveToAnswers(answer)
      setSaved(true)
    }

    setTimeout(() => {
      setSaveButtonColour("bg-primary")
      setSaveButtonText("Save")
    }, 1000)
  }

  return (
    <div>
      <div className="mb-2 text-sm text-gray-500">
        {props.question.properties.formula} =
      </div>
      <input
        type="text"
        className={`w-3/4 text-right outline-none border-2 border-${
          valid ? "gray" : "red"
        }-300 focus:border-2 focus:border-yellow-500 focus:rounded rounded-lg p-2 text-left`}
        value={answer}
        readOnly
        autoComplete="off"
      />{" "}
      &nbsp;
      {props.question.properties.unit}
      <QuestionStatus
        question={props.question}
        answer={answer}
        saveAnswer={saveAnswer}
        saved={saved}
        saveButtonColour={saveButtonColour}
        setSaveButtonColour={setSaveButtonColour}
        saveButtonText={saveButtonText}
        setSaveButtonText={setSaveButtonText}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        comment={props.comment}
        saveComment={props.saveComment}
        conformance={props.conformance}
        saveConformance={props.saveConformance}
      />
    </div>
  )
}
