import React, { useEffect, useState } from "react"
import { useStoreValue } from "react-context-hook"
import { QuestionStatus } from "../saveQuestion"
import { translateSurveyText } from "../../../../utils/translate"

export const DropdownQuestion = (props) => {
  const [answer, setAnswer] = useState(props.answer || "")
  const [saved, setSaved] = useState(false)
  const [valid, setValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [saveButtonText, setSaveButtonText] = useState("Save")
  const [saveButtonColour, setSaveButtonColour] = useState("bg-primary")

  const lang = useStoreValue("language")

  useEffect(() => {
    if (props.answer) setSaved(true)
  }, [])

  const updateAnswer = (value) => {
    setAnswer(value)
    setSaved(false)
  }

  const saveAnswer = () => {
    if (answer === "") {
      setValid(false)
      setErrorMessage("You must select an answer.")
      setSaveButtonColour("bg-red-600")
      setSaveButtonText("Error!")

      props.removeFromAnswers()
      setSaved(false)
    } else {
      setValid(true)
      setErrorMessage(null)
      setSaveButtonColour("bg-green-600")
      setSaveButtonText("Saved!")

      props.saveToAnswers(answer)
      setSaved(true)
    }

    setTimeout(() => {
      setSaveButtonColour("bg-primary")
      setSaveButtonText("Save")
    }, 1000)
  }

  return (
    <div>
      <div className="relative inline-block w-full">
        <select
          className={`w-full py-2 pl-3 pr-8 outline-none text-base border-2 border-${
            valid ? "gray" : "red"
          }-300 rounded-lg appearance-none focus:border-2 focus:border-yellow-500 focus:rounded`}
          value={answer}
          onChange={(e) => updateAnswer(e.target.value)}
        >
          <option value="">Select an answer...</option>
          {props.question.properties.options.map((option) => (
            <option key={option} value={option}>
              {translateSurveyText(
                lang,
                option,
                props.survey.survey_schema_translations
              )}
            </option>
          ))}
        </select>
      </div>
      <QuestionStatus
        question={props.question}
        answer={answer}
        saveAnswer={saveAnswer}
        saved={saved}
        saveButtonColour={saveButtonColour}
        setSaveButtonColour={setSaveButtonColour}
        saveButtonText={saveButtonText}
        setSaveButtonText={setSaveButtonText}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        comment={props.comment}
        saveComment={props.saveComment}
        conformance={props.conformance}
        saveConformance={props.saveConformance}
      />
    </div>
  )
}
