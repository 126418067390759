import React, { useEffect, useState } from "react"
import { useStore } from "react-context-hook"
import { useHistory } from "react-router-dom"
import { Header } from "../components/header"
import { NavBar } from "../components/navbar"
import { SurveysListItem } from "../components/surveysListItem"
import { FaAngleRight } from "react-icons/fa"
import { evaluateQuestionDisplayConditions } from "../../utils"
import { useOnlineStatus } from "../../utils"
import { db } from "../../utils/indexedDb"
import { useLiveQuery } from "dexie-react-hooks"
import { getAssessments } from "../../Routes"
import DataLoadingContainer from "../utils/loader/DataLoadingContainer"
import TranslationWrapper from "../utils/translationWrapper"

const getSurveys = (account, surveys) => {
  const options = {
    credentials: "include",
  }
  return Promise.all(
    surveys.map((survey) =>
      fetch(
        `${process.env.REACT_APP_API_HOST}/${account.tenant.identifier}/survey/${survey.unique_id}`,
        options
      )
        .then((res) => res.json())
        .then((res) => {
          // res.data[0].unique_id = survey.unique_id
          // res.data[0].submitted = survey.submitted || false
          // filter surveys for ones where current user is assessor
          if (res.status === 200 && res.data?.length) {
            if (
              account.role === "admin" ||
              res.data[0].assessors.some((a) => a.email === account.email)
            )
              return res
          }
          return null
        })
    )
  )
}

const getFarmManagers = (farmID, tenant) => {
  return fetch(
    `${process.env.REACT_APP_API_HOST}/${tenant}/farms/${farmID}/users`,
    { credentials: "include" }
  )
    .then((res) => res.json())
    .then((res) =>
      res.data?.filter((u) => u.manager && JSON.parse(u.manager) == true)
    )
}

export const Assess = (props) => {
  const [account, setAccount] = useStore("account", null)
  const [assessments, setAssessments] = useStore("assessments", null)
  const [assessment, setAssessment] = useStore("assessment", null)
  const [managers, setManagers] = useState(null)
  const [surveys, setSurveys] = useStore("surveys", null)
  const [answers, setAnswers] = useStore("answers", null)
  const [thisSurveys, setThisSurveys] = useState([])
  const [signedOffSurveys, setSignedOffSurveys] = useState([])
  const [surveysToReview, setSurveysToReview] = useState(null)
  const [pendingSurveysCount, setPendingSurveysCount] = useState(null)
  const [submittedSurveys, setSubmittedSurveys] = useState(0)
  const [profileVerified, setProfileVerified] = useState(false)
  const [notSubmittedSurveys, setNotSubmittedSurveys] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const allFiles = useLiveQuery(() => db.items.toArray(), [])
  const online = useOnlineStatus()
  const history = useHistory()

  const getCorrectSurveyVersion = (assessmentSurveys) => {
    const correctSurveys = []
    assessmentSurveys.forEach((survey) => {
      if (answers && answers.hasOwnProperty(survey.unique_id)) {
        if (
          answers[survey.unique_id]?.schema.version === survey.schema.version
        ) {
          correctSurveys.push(survey)
        } else {
          correctSurveys.push(answers[survey.unique_id])
        }
      } else {
        correctSurveys.push(survey)
      }
    })
    return correctSurveys
  }

  useEffect(() => {
    setIsLoading(true)
    if (assessments) {
      const thisAssessment = assessments.filter(
        (assmnt) => assmnt.unique_id == props.match.params.assessmentId
      )[0]
      if (!thisAssessment) {
        history.push("/assessments")
        return
      }
      const profileVerified =
        localStorage.getItem(`profile_verified_${thisAssessment.unique_id}`) ||
        false
      setAssessment(thisAssessment)
      setProfileVerified(profileVerified)
      getSurveys(account, thisAssessment.surveys || []).then((res) => {
        let s = res.map((r) => {
          if (
            r.data[0].schema.require_profile_verification === true &&
            !profileVerified
          ) {
            r.data[0].disabled = true
            r.data[0].disabled_reason =
              "The farm profile must be verified before this survey can be completed"
          } else {
            r.data[0].disabled = false
            r.data[0].disabled_reason = null
          }
          return r.data[0]
        })
        // setSurveys(s)
        // setThisSurveys(s)
        setSurveys(getCorrectSurveyVersion(s))
        setThisSurveys(getCorrectSurveyVersion(s))
      })
      getFarmManagers(
        thisAssessment.subject.unique_id,
        account.tenant.identifier
      ).then((managers) => setManagers(managers))
    }
    setIsLoading(false)
  }, [assessments])

  useEffect(() => {
    if (surveys && thisSurveys) {
      if (answers) {
        let surveysToReview = []
        let surveysCompleted = 0
        let signedOffSurveys = []
        let surveysSubmitted = 0
        thisSurveys.forEach((survey) => {
          let sectionsCompleted = 0
          if (answers[survey.unique_id]) {
            answers[survey.unique_id].schema.sections.forEach(
              (section, sectionIdx) => {
                let questionsCompleted = 0
                section.questions.forEach((q) => {
                  if (
                    q.hasOwnProperty("answer") ||
                    !q.required ||
                    !evaluateQuestionDisplayConditions(
                      q,
                      answers,
                      survey,
                      assessment.subject.species,
                      sectionIdx
                    ) ||
                    q.type === "blank"
                  ) {
                    questionsCompleted++
                  }
                })
                if (questionsCompleted === section.questions.length) {
                  sectionsCompleted++
                }
              }
            )
            if (
              sectionsCompleted ===
              answers[survey.unique_id].schema.sections.length
            ) {
              surveysCompleted++
              if (
                !survey.submitted &&
                answers[survey.unique_id]?.schema?.assessor_signature &&
                answers[survey.unique_id]?.schema?.farmer_signature
              ) {
                signedOffSurveys.push(survey)
              } else if (!survey.submitted) surveysToReview.push(survey)
            }
            if (survey.submitted) {
              surveysSubmitted++
            }
          }
        })
        setSignedOffSurveys(signedOffSurveys)
        setSurveysToReview(surveysToReview)
        setPendingSurveysCount(surveys.length - surveysCompleted)
        setSubmittedSurveys(surveysSubmitted)
      } else {
        setPendingSurveysCount(surveys.length)
      }
    }
  }, [thisSurveys, answers])

  const submitSurveys = () => {
    setSubmitted(true)
    const data = signedOffSurveys
    return fetch(
      `${process.env.REACT_APP_API_HOST}/${account.tenant.identifier}/assess/${assessment.unique_id}/submit`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          surveys: data,
          subjectProfile: assessment.subject?.submitted
            ? null
            : assessment.subject || null,
        }),
        credentials: "include",
      }
    ).then((res) => res.json())
  }

  const submitFiles = () => {
    return Promise.all(
      allFiles.map((file) => {
        const formData = new FormData()
        formData.append(file.unique_id, file.data)
        return fetch(
          `${process.env.REACT_APP_API_HOST}/${account.tenant.identifier}/assess/submitFile/${file.unique_id}`,
          {
            method: "POST",
            body: formData,
            credentials: "include",
          }
        )
      })
    ).catch((err) => {
      console.log(err)
    })
  }

  const submit = () => {
    setIsLoading(true)
    setSubmitError(false)
    Promise.all([submitSurveys(), submitFiles()])
      .then(([resS, resF]) => {
        if (resS.status === 200) {
          setSubmitted(true)
          setShowConfirm(false)
          getAssessments(
            account.tenant.identifier,
            account.role,
            account.unique_id
          ).then((res) => {
            let remainingAssessments = res.filter((a) =>
              a.surveys.some((s) => !s.submitted)
            )

            remainingAssessments.forEach((asses) => {
              const answersCopy = { ...answers }
              asses.surveys.forEach((sur) => {
                if (sur.submitted && answersCopy[sur.unique_id]) {
                  answersCopy[sur.unique_id]["submitted"] = sur.submitted
                }
              })
              localStorage.setItem("answers", JSON.stringify(answersCopy))
            })

            let submittedAssessment = remainingAssessments.find(
              (a) => a.unique_id === assessment.unique_id
            )
            if (submittedAssessment?.subject) {
              submittedAssessment.subject.submitted = true
              localStorage.setItem(
                `profile_verified_${submittedAssessment.unique_id}`,
                JSON.stringify(submittedAssessment.subject)
              )
            } else if (!submittedAssessment) {
              localStorage.removeItem(
                `profile_verified_${assessment.unique_id}`
              )
            }
            setAssessments(remainingAssessments)

            if (resS?.data.notSubmittedSurveys.length > 0) {
              setNotSubmittedSurveys(resS.data.notSubmittedSurveys)
            } else {
              history.push("/assessments")
            }
          })
        } else {
          setSubmitted(false)
          setSubmitError(true)
          console.log("Error submitting assessment surveys...")
        }
        setIsLoading(false)
      })
      .catch((error) => {
        if (!window.navigator.onLine) {
          console.log("Scheduled for later")
          setSubmitted(true)
          setShowConfirm(false)
        } else {
          console.log("Error submitting assessment surveys...")
        }
      })
  }

  return (
    <>
      {/* <Header /> */}
      <NavBar
        text={
          thisSurveys.length > 0 && pendingSurveysCount === 0
            ? `All surveys completed`
            : `${pendingSurveysCount || "No"} surveys pending`
        }
        iconLeft="back"
        iconRight="account"
        route="/assessments"
      />
      <DataLoadingContainer loading={isLoading}>
        {assessment && (
          <>
            <div className="mt-2 mb-2 p-4 text-gray-500 text-center">
              <span className="font-semibold text-xl text-gray-800">
                {assessment.subject.name}
              </span>
              {assessment.subject.business_name ? (
                <>
                  <br />
                  {assessment.subject.business_name}
                </>
              ) : null}
              <br />
              {assessment.subject.postcode}
              <br />
              {managers?.length ? <h2 className="font-bold">Managers:</h2> : ""}
              {managers?.map((m, i) => (
                <React.Fragment key={i}>
                  {m.firstname + " " + m.lastname} <br />
                  {m.email || ""} <br />
                  {m.telephone || ""}
                </React.Fragment>
              ))}
            </div>
            <div className="grid grid-cols-1 justify-center">
              <button
                className="m-auto p-2 rounded border-2 border-green-600"
                onClick={() => {
                  history.push(
                    props.match.params.assessmentId + "/verify-profile"
                  )
                }}
              >
                {" "}
                {profileVerified
                  ? "Edit Farm Profile"
                  : "Verify Farm Profile"}{" "}
              </button>
            </div>
          </>
        )}
        {surveysToReview?.length ? (
          <div className="mx-auto m-4 mt-2 mb-6 p-8 border-2 border-green-600 rounded-xl w-4/5 md:w-1/2 lg:w-1/4 text-center">
            <div className="font-semibold text-3xl text-green-600">
              Assessment Surveys Complete
            </div>
            <div className="mt-4 font-semibold text-xl text-gray-500">
              Now ready for review:
              <ul className="font-normal text-lg text-gray-500">
                {surveysToReview.map((s) => (
                  <li>
                    <TranslationWrapper
                      translations={s.survey_schema_translations}
                    >
                      {s.schema.title}
                    </TranslationWrapper>
                  </li>
                )) || null}
              </ul>
            </div>
            <button
              className="mt-6 rounded-xl p-1 px-8 pr-4 text-white text-2xl font-semibold bg-primary"
              onClick={() => {
                history.push(props.match.params.assessmentId + "/review")
              }}
            >
              Review
              <FaAngleRight className="inline text-4xl" />
            </button>
          </div>
        ) : null}
        {signedOffSurveys?.length ? (
          <div
            className={`mx-auto m-4 mt-2 mb-8 p-8 border-2 ${
              online ? "border-green-600" : "border-orange-600"
            } rounded-xl w-4/5 md:w-1/2 lg:w-1/4 text-center`}
          >
            <div
              className={`font-semibold text-3xl ${
                online ? "text-green-600" : "text-orange-600"
              }`}
            >
              Signed off surveys
            </div>
            <div className="mt-4 font-semibold text-xl text-gray-500">
              The following surveys have already been reviewed and signed off:
              <ul className="font-normal text-lg text-gray-500">
                {signedOffSurveys?.map((s) => (
                  <li>
                    <TranslationWrapper
                      translations={s.survey_schema_translations}
                    >
                      {s.schema.title}
                    </TranslationWrapper>
                  </li>
                )) || null}
              </ul>
            </div>
            {online ? (
              <button
                className="mt-6 rounded-xl p-1 px-8 pr-4 text-white text-2xl font-semibold bg-primary"
                onClick={() => setShowConfirm(true)}
              >
                Submit
                <FaAngleRight className="inline text-4xl" />
              </button>
            ) : (
              <p className="mt-4 font-semibold text-lg">
                You are offline. Please connect to the internet to submit the
                surveys
              </p>
            )}
          </div>
        ) : null}
        <div className="mx-auto border-t-2 border-gray-500 md:border-t-0 md:w-1/2 lg:w-1/4 md:pt-5">
          {thisSurveys.map((survey) => (
            <SurveysListItem
              key={survey.unique_id}
              assessmentId={props.match.params.assessmentId}
              survey={survey}
              submitted={survey.submitted}
              disabled={survey.disabled}
              disabledReason={survey.disabled_reason}
            />
          ))}
        </div>

        {showConfirm && (
          <div className="inset-0 z-20 flex fixed m-auto min-h-screen bg-black bg-opacity-50">
            <div className="bg-white mx-auto mt-48 p-1 pt-8 text-gray-500 text-center w-full h-40 md:w-1/2 lg:w-1/4 md:mx-auto">
              {submitted ? (
                <>
                  <div className="text-green-600 text-3xl font-semibold mt-1">
                    Submitting assessment
                  </div>
                  <div className="text-gray-500 text-xl font-semibold mt-5">
                    Please wait . . .
                  </div>
                </>
              ) : (
                <>
                  {submitError ? (
                    <>
                      <div className="text-red-600 text-xl font-semibold mt-1">
                        There was an error submitting the assessment.
                      </div>
                    </>
                  ) : (
                    <div className="text-gray-700 text-lg font-semibold">
                      OK to submit completed surveys?
                      <ul className="text-gray-500 text-base">
                        {signedOffSurveys.map((s) => (
                          <li>
                            <TranslationWrapper
                              translations={s.survey_schema_translations}
                            >
                              {s.schema.title}
                            </TranslationWrapper>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <div className="">
                    <div className="inline">
                      <button
                        className="mt-6 rounded-xl p-1 px-5 text-white text-2xl font-semibold bg-green-600"
                        onClick={() => submit()}
                      >
                        {submitError ? "Retry" : "OK"}
                      </button>
                    </div>
                    <div className="inline ml-6">
                      <button
                        className="mt-6 rounded-xl p-1 px-5 text-white text-2xl font-semibold bg-gray-400"
                        onClick={() => {
                          setShowConfirm(false)
                          setSubmitError(false)
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {notSubmittedSurveys && (
          <>
            <div className="inset-0 z-20 flex justify-center items-center fixed m-auto min-h-screen bg-black bg-opacity-50">
              <div className="my-6 px-6 mx-auto md:w-1/2 lg:w-1/4 flex flex-col flex-nowrap justify-center bg-white p-8 rounded-md">
                <>
                  <div className="text-gray-700 text-lg font-semibold flex flex-col items-center text-center">
                    You do not have a valid training certificate to submit the
                    following surveys
                  </div>
                  <div className="text-gray-700 text-lg font-semibold flex flex-col items-center text-center">
                    <ul className="text-gray-500 text-base pt-2">
                      {notSubmittedSurveys.map((survey) => {
                        return (
                          <li key={survey.unique_id}>{survey.schema.title}</li>
                        )
                      })}
                    </ul>
                  </div>
                </>
                <div className="flex justify-evenly pt-6">
                  <div className="">
                    <button
                      className="rounded-xl p-1 px-5 text-white text-2xl font-semibold bg-green-600"
                      onClick={() => history.push("/assessments")}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </DataLoadingContainer>
    </>
  )
}
