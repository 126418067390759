import { useEffect, useState } from "react"
import { CommentBox } from "./commentBox"
import { Conformance } from "./conformance"

// Extracts the save component in all questions to
// its own component to make modification easier
export const QuestionStatus = (props) => {
  const [conformance, setConformance] = useState(
    props.conformance || { conformant: true, comment: "" }
  )
  const [showConformance, setShowConformance] = useState(false)
  const [showConformanceOptions, setShowConformanceOptions] = useState(false)
  const [comment, setComment] = useState(props.comment || "")

  const checkConformanceStatus = () => {
    if (!props.answer) return true
    if (props.answer == props.question.compliant_answer) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (!props.question.compliant_question) return
    if (props.question.compliant_answer) {
      const compliant = checkConformanceStatus()
      setShowConformance(!compliant)
      setConformance((curr) => ({ ...curr, conformant: compliant }))
    } else {
      setShowConformance(true)
      setShowConformanceOptions(true)
    }
  }, [props.answer])

  const handleSave = () => {
    if (props.question.compliant_question) {
      props.saveConformance(conformance)
      props.saveAnswer()
      if (props.question.allow_comment) props.saveComment(comment)
    } else if (!props.question.compliant_question) {
      props.saveAnswer()
      if (props.question.allow_comment) props.saveComment(comment)
    } else {
      props.setErrorMessage("You must select a conformance status.")
      props.setSaveButtonColour("bg-red-600")
      props.setSaveButtonText("Error!")
    }
  }

  return (
    <>
      {showConformance && (
        <Conformance
          conformance={conformance}
          setConformance={setConformance}
          conformanceOptions={showConformanceOptions}
        />
      )}
      {props.question.allow_comment ? (
        <CommentBox comment={comment} setComment={setComment} />
      ) : null}
      <div className="mt-2 text-red-500">{props.errorMessage}</div>
      <div className="mt-4">
        <button
          type="button"
          className={`transition duration-150 rounded-lg p-2 w-24 ${props.saveButtonColour} text-white font-semibold`}
          onClick={handleSave}
        >
          {props.saveButtonText}
        </button>
      </div>
      {/* <div className="mt-6 font-semibold">
        <div className="text-2xl">
          {saved &&
            <span className="text-green-600"><FaRegCheckSquare className="inline" /></span>
          }
          {!saved &&
            <span className="text-gray-400"><FaRegSquare className="inline" /></span>
          }
        </div>
      </div> */}
    </>
  )
}
