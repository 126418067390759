import React from "react"
import { useHistory } from "react-router"
import { FaAngleRight } from "react-icons/fa"

export const CaptureGroupListItem = ({ captureGroup }) => {
  const history = useHistory()

  return (
    <div className="p-3 border-b-2 border-gray-500 md:border md:rounded-lg md:mb-4">
      <div className="float-right">
        <div className="text-4xl p-1 rounded-full bg-primary text-white mt-1">
          <FaAngleRight
            className="md:cursor-pointer"
            onClick={() =>
              history.push(`/capture-group/${captureGroup.unique_id}`)
            }
          />
        </div>
      </div>
      <div className="text-2xl font-semibold">{captureGroup.subject.name}</div>
      <div className="text-gray-500">
        {captureGroup.authorizer.name} <br />
        {captureGroup.subject.business_name ? (
          <>
            {" "}
            {captureGroup.subject.business_name} <br />{" "}
          </>
        ) : null}
        {captureGroup.subject.postcode}
      </div>
    </div>
  )
}
