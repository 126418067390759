import React, { useEffect, useState } from "react"
import { useStore } from "react-context-hook"
import { useHistory } from "react-router"
import { FaAngleRight } from "react-icons/fa"
import { evaluateQuestionDisplayConditions } from "../../utils"
import DataLoadingContainer from "../utils/loader/DataLoadingContainer"
import TranslationWrapper from "../utils/translationWrapper"

export const SurveysListItem = (props) => {
  const [answers, setAnswers] = useStore("answers", null)
  const [captureGroup, setCaptureGroup] = useStore("captureGroup", null)
  const [assessment, setAssessment] = useStore("assessment", null)
  const [completed, setCompleted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [submittedCount, setSubmittedCount] = useState(0)
  const history = useHistory()

  const getSectionsCompletedCount = () => {
    if (answers) {
      let sectionsCompletedCount = 0
      if (answers[props.survey.unique_id]) {
        answers[props.survey.unique_id].schema.sections.forEach(
          (section, sectionIdx) => {
            let answeredCount = 0
            section.questions.forEach((q) => {
              if (
                q.hasOwnProperty("answer") ||
                !q.required ||
                !evaluateQuestionDisplayConditions(
                  q,
                  answers,
                  props.survey,
                  assessment?.subject.species || captureGroup?.subject.species,
                  sectionIdx
                ) ||
                q.type === "blank"
              ) {
                answeredCount++
              }
            })
            if (answeredCount === section.questions.length) {
              sectionsCompletedCount++
            }
          }
        )
      }

      return sectionsCompletedCount
    } else {
      return 0
    }
  }

  useEffect(() => {
    setCompleted(
      getSectionsCompletedCount() == props.survey.schema.sections.length
    )
  }, [answers])

  useEffect(() => {
    setSubmittedCount(
      captureGroup?.surveys
        .filter(
          (survey) => survey.survey_unique_id === props.survey.survey_unique_id
        )
        .filter((filteredSurvey) => filteredSurvey.submitted).length
    )
  }, [])

  return (
    <DataLoadingContainer loading={isLoading}>
      <div className="p-3 border-b-2 border-gray-500 h-18 md:border md:rounded-lg md:mb-4">
        <div className="float-right">
          <div
            className={`text-4xl p-1 mt-1 rounded-full ${
              props.submitted || props.disabled ? "bg-gray-300" : "bg-primary"
            } text-white`}
          >
            {props.submitted || props.disabled ? (
              <FaAngleRight className="" />
            ) : props.capturing ? (
              <FaAngleRight
                className="md:cursor-pointer"
                onClick={() =>
                  history.push(
                    `/capture-group/${props.captureGroupId}/survey/${props.survey.unique_id}`
                  )
                }
              />
            ) : (
              <FaAngleRight
                className="md:cursor-pointer"
                onClick={() =>
                  history.push(
                    `/assessment/${props.assessmentId}/survey/${props.survey.unique_id}`
                  )
                }
              />
            )}
          </div>
        </div>
        <div className="text-2xl font-semibold">
          <TranslationWrapper
            translations={props.survey.survey_schema_translations}
          >
            {props.survey.schema.title}
          </TranslationWrapper>
        </div>
        {props.submitted ? <strong>Submitted</strong> : null}
        {props.submitted ? null : (
          <div className="text-gray-500">
            Sections completed &nbsp;
            <span className={`font-semibold ${completed && "text-green-600"}`}>
              {getSectionsCompletedCount()} /{" "}
              {props.survey.schema.sections.length}
            </span>
          </div>
        )}
        {submittedCount ? (
          <div className="text-gray-500">
            Submitted: {submittedCount} &nbsp;
          </div>
        ) : null}

        {completed && props.capturing ? (
          <div
            className="cursor-pointer p-2 bg-primary rounded-lg text-white"
            onClick={() => props.initializeNewSurvey(props.survey)}
          >
            {" "}
            Fill in another survey (stash this one for later submission)
          </div>
        ) : null}
        {props.disabled === true && props.disabledReason !== null && (
          <p className="text-red-500">{props.disabledReason}</p>
        )}
      </div>
    </DataLoadingContainer>
  )
}
