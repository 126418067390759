import React, { useEffect, useState } from "react"
import { useStore } from "react-context-hook"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useParams,
  useHistory,
} from "react-router-dom"

import {
  Home,
  Login,
  CreateAssessor,
  Assessments,
  CaptureGroups,
  Capture,
  SignOff,
  Review,
  Assess,
  FarmProfileVerify,
  Survey,
  Section,
} from "./views"
import { Header } from "./views/components/header"
import Unauthorized from "./views/unauthorized"
import DataLoadingContainer from "./views/utils/loader/DataLoadingContainer"

const getTenantTranslationIds = async (tenant) => {
  return await fetch(
    `${process.env.REACT_APP_API_HOST}/${tenant}/survey/schemas`,
    {
      method: "GET",
      redirect: "follow",
      credentials: "include",
    }
  )
    .then((res) => res.json())
    .then((data) => {
      const translationIds = ["en"]
      console.log(data.data)
      data.data.forEach((schema) => {
        schema.survey_schema_translations.forEach((translation) => {
          if (!translationIds.includes(translation.language))
            translationIds.push(translation.language)
        })
      })
      return translationIds
    })
}

const authenticate = () => {
  return fetch(`${process.env.REACT_APP_API_HOST}/auth/authenticate`, {
    method: "GET",
    redirect: "follow",
    credentials: "include",
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === 200) {
        return { ok: true, data: data.data }
      } else {
        return { ok: false, data: data.data }
      }
    })
    .catch((error) => {
      console.log(error)
      return false
    })
}

const getBranding = async (tenant) => {
  console.log(tenant, "tenant")
  return await fetch(`${process.env.REACT_APP_API_HOST}/branding/`, {
    credentials: "include",
  })
    .then((res) => res.json())
    .catch((err) => console.error(err))
}

const loadAnswers = () => {
  if (localStorage.getItem("answers")) {
    return JSON.parse(localStorage.getItem("answers"))
  }
}

export const getAssessments = (tenant, userRole, userID) => {
  let url = "assessments"
  if (userRole === "assessor") {
    url = "assessments/assessor"
  }

  const headers = new Headers()
  headers.append("Content-Type", "application/json")

  const options = {
    method: "GET",
    headers: headers,
    // body: JSON.stringify({
    //   where: null
    // }),
    credentials: "include",
  }
  return fetch(`${process.env.REACT_APP_API_HOST}/${tenant}/${url}`, options)
    .then((res) => res.json())
    .then((res) => {
      return res.data.map((assessment) => {
        let profileVerification =
          localStorage.getItem(`profile_verified_${assessment.unique_id}`) ||
          false
        if (profileVerification) {
          assessment.subject = JSON.parse(profileVerification)
        }
        return assessment
      })
    })
    .catch((err) => console.error(err))
}

export const getCaptureGroups = (tenant, userRole, userID) => {
  let url = "captureGroups"
  if (userRole === "assessor") {
    url = "captureGroups/assessor"
  }

  const headers = new Headers()
  headers.append("Content-Type", "application/json")

  const options = {
    method: "GET",
    headers: headers,
    // body: JSON.stringify({
    //   where: null
    // }),
    credentials: "include",
  }
  return fetch(`${process.env.REACT_APP_API_HOST}/${tenant}/${url}`, options)
    .then((res) => res.json())
    .then((res) => {
      return res.data.map((capture_group) => {
        let profileVerification =
          localStorage.getItem(`profile_verified_${capture_group.unique_id}`) ||
          false
        if (profileVerification) {
          capture_group.subject = JSON.parse(profileVerification)
        }
        return capture_group
      })
    })
    .catch((err) => console.error(err))
}

const getAllSurveys = (tenant) => {
  const options = {
    credentials: "include",
  }
  return fetch(
    `${process.env.REACT_APP_API_HOST}/${tenant}/survey`,
    options
  ).then((res) => res.json())
}

const getSpeciesConfig = (tenant) => {
  return fetch(`${process.env.REACT_APP_API_HOST}/${tenant}/config/species`, {
    method: "GET",
    redirect: "follow",
    credentials: "include",
  }).then((res) => res.json())
}

const getAppConfig = (tenant) => {
  return fetch(
    `${process.env.REACT_APP_API_HOST}/${tenant}/config/assessmentsSettings`,
    {
      method: "GET",
      redirect: "follow",
      credentials: "include",
    }
  ).then((res) => res.json())
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation()
  const params = useParams()
  const history = useHistory()
  const [authenticated, setAuthenticated] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [account, setAccount] = useStore("account", 0)
  const [showAccountModal, setShowAccountModal] = useStore(
    "showAccountModal",
    false
  )

  const [branding, setBranding] = useStore("branding", null)
  const [answers, setAnswers] = useStore("answers", null)
  const [assessments, setAssessments] = useStore("assessments", null)
  const [captureGroups, setCaptureGroups] = useStore("captureGroups", null)
  const [surveys, setSurveys] = useStore("surveys", null)
  const [speciesConfig, setSpeciesConfig] = useStore("speciesConfig", null)
  const [appConfig, setAppConfig] = useStore("appConfig", null)
  const [translationIds, setTranslationIds] = useStore("translationIds", null)

  const getCorrectSurveyVersion = (surveys, answers) => {
    const correctSurveys = []
    surveys.forEach((survey) => {
      if (answers?.hasOwnProperty(survey.unique_id)) {
        if (
          answers[survey.unique_id]?.schema.version === survey.schema.version
        ) {
          // if the survey version has not changed
          correctSurveys.push(survey)
        } else {
          // if the survey has been started
          correctSurveys.push(answers[survey.unique_id])
        }
      } else {
        // if the survey has not been started
        correctSurveys.push(survey)
      }
    })
    return correctSurveys
  }

  useEffect(() => {
    ;(async () => {
      //if (window.navigator.onLine) {
      const auth = await authenticate().then((auth) => {
        if (auth?.ok) {
          if (!["admin", "retailer", "assessor"].includes(auth.data?.role))
            return history.push("/403")
          setAuthenticated(auth.ok)
          setAccount(auth.data)
          setShowAccountModal(false)
          return auth.data
        } else {
          history.push("/login")
        }
      })

      if (auth) {
        let brandingProm,
          assessmentsProm,
          captureGroupsProm,
          surveysProm,
          speciesProm,
          appConfigProm

        if (!translationIds) {
          setTranslationIds(
            await getTenantTranslationIds(auth.tenant.identifier)
          )
        }

        if (!branding) {
          brandingProm = await getBranding(auth.tenant.label).then((res) =>
            setBranding(res.data)
          )
        }

        if (!answers && loadAnswers()) {
          setAnswers(loadAnswers())
        }

        if (!assessments) {
          assessmentsProm = await getAssessments(
            auth.tenant.identifier,
            auth.role,
            auth.unique_id
          ).then((res) => {
            setAssessments(
              res.filter((a) => a.surveys.some((s) => !s.submitted))
            )
          })
        }

        if (!captureGroups) {
          captureGroupsProm = await getCaptureGroups(
            auth.tenant.identifier,
            auth.role,
            auth.unique_id
          ).then((res) => {
            setCaptureGroups(
              res.filter((a) => a.surveys.some((s) => !s.submitted))
            )
          })
        }

        // if (!surveys) {
        //   surveysProm = await getAllSurveys(auth.tenant.identifier).then(
        //     (res) => setSurveys(res.data)
        //   )
        // }

        if (!surveys) {
          surveysProm = await getAllSurveys(auth.tenant.identifier).then(
            (res) => {
              setSurveys(getCorrectSurveyVersion(res.data, loadAnswers()))
            }
          )
        }

        if (!speciesConfig) {
          speciesProm = await getSpeciesConfig(auth.tenant.identifier).then(
            (res) => setSpeciesConfig(res.data)
          )
        }

        if (!appConfig) {
          appConfigProm = await getAppConfig(auth.tenant.identifier).then(
            (res) => setAppConfig(res.data)
          )
        }

        await Promise.all([
          brandingProm,
          assessmentsProm,
          captureGroupsProm,
          surveysProm,
          speciesProm,
          appConfigProm,
        ])
          .then(() => setLoaded(true))
          .catch((error) => console.log(error))

        // } else {

        //   // We are offline

        //   setShowAccountModal(false)
        //   if (!answers && loadAnswers()) {
        //     setAnswers(loadAnswers())
        //   }
        //   if (!assessments) {
        //     getAssessments(account.tenant.identifier, account.role, account.unique_id)
        //     .then(res => setAssessments(res.data))
        //   }
        //   if (!captureGroups) {
        //     getCaptureGroups(account.tenant.identifier, account.role, account.unique_id)
        //       .then(res => setCaptureGroups(res.data))
        //   }
        //   if (!surveys) {
        //     getAllSurveys(account.tenant.identifier)
        //     .then(res => setSurveys(res.data))
        //   }
        //   if (!speciesConfig) {
        //     getSpeciesConfig(account.tenant.identifier)
        //     .then(res => setSpeciesConfig(res.data))
        //   }
        //   setLoaded(true)
        // }
      }
    })()
  }, [])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!loaded) {
          return <DataLoadingContainer />
        } else {
          if (authenticated) {
            return <Component {...props} />
          } else {
            return <Redirect to="/login" />
          }
        }
      }}
    />
  )
}

export const Routes = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route component={Unauthorized} path="/403" />
        <Route
          path="/login/:selectProfile?"
          exact
          component={(props) => <Login />}
        />

        <Route
          path="/create-assessor/:uid"
          exact
          component={(props) => <CreateAssessor />}
        />
        <PrivateRoute
          component={Section}
          path="/assessment/:assessmentId/survey/:surveyId/section/:sectionId"
        />
        <PrivateRoute
          component={Section}
          path="/capture-group/:captureGroupId/survey/:surveyId/section/:sectionId"
        />
        <PrivateRoute
          component={Survey}
          path="/assessment/:assessmentId/survey/:surveyId"
        />
        <PrivateRoute
          component={Survey}
          path="/capture-group/:captureGroupId/survey/:surveyId"
        />
        <PrivateRoute
          component={SignOff}
          path="/assessment/:assessmentId/sign-off"
        />
        <PrivateRoute
          component={Review}
          path="/assessment/:assessmentId/review"
        />
        <PrivateRoute
          component={FarmProfileVerify}
          path="/assessment/:assessmentId/verify-profile"
        />
        <PrivateRoute component={Assess} path="/assessment/:assessmentId" />
        <PrivateRoute component={Assessments} path="/assessments" />
        <PrivateRoute
          component={Capture}
          path="/capture-group/:captureGroupId"
        />
        <PrivateRoute component={CaptureGroups} path="/capture-groups" />
        <PrivateRoute component={Home} path="/" />
      </Switch>
    </Router>
  )
}
