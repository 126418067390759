import React from "react"
import { useStore } from "react-context-hook"
import { getAssessments } from "../../Routes"
import { MdRefresh } from "react-icons/md"

export const RefreshIcon = () => {
  const [assessments, setAssessments] = useStore("assessments", [])
  const [account, setAccount] = useStore("account", 0)

  const refreshAssessments = () => {
    if (account)
      getAssessments(
        account.tenant.identifier,
        account.role,
        account.unique_id
      ).then((res) =>
        setAssessments(res.filter((a) => a.surveys.some((s) => !s.submitted)))
      )
  }

  return (
    <div className="w-10 h-full pt-1" onClick={() => refreshAssessments()}>
      <MdRefresh className="text-4xl mx-auto md:cursor-pointer" />
    </div>
  )
}
