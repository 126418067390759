import React, { useEffect, useState } from "react"
import { useStore } from "react-context-hook"
import { MdAccountCircle } from "react-icons/md"
import { AiOutlineClose } from "react-icons/ai"
import { languages } from "../../config/languages"
import { useHistory } from "react-router-dom"

export const AccountIcon = () => {
  const [language, setLanguage] = useStore("language", "en")
  const [sortedLanguages, setSortedLanguages] = useState(null)
  const [account, setAccount] = useStore("account", null)
  const [showAccountModal, setShowAccountModal] = useStore(
    "showAccountModal",
    false
  )
  const history = useHistory()
  const [translationIds, setTranslationIds] = useStore("translationIds")
  useEffect(() => {
    const _sortedLanguages = sortLanguages(getSpecificTenantLanguages())
    setSortedLanguages(_sortedLanguages)
  }, [])

  const getSpecificTenantLanguages = () => {
    const lang = {}
    translationIds.forEach((id) => {
      lang[id] = languages[id]
    })
    return lang
  }

  const sortLanguages = (languages) => {
    return Object.keys(languages).sort((a, b) => {
      let aName = languages[a].name.toUpperCase()
      let bName = languages[b].name.toUpperCase()
      if (aName < bName) {
        return -1
      }
      if (aName > bName) {
        return 1
      }
      return 0
    })
  }

  const submitLogout = () => {
    fetch(`${process.env.REACT_APP_API_HOST}/auth/logout`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      credentials: "include",
    })
      .then((res) => res.json())
      .finally(() => {
        history.push("/login")
      })
  }

  const updatePreferredLanguage = (newLanguage) => {
    localStorage.setItem("language", newLanguage)
    setLanguage(newLanguage)
  }

  return (
    <>
      <div
        className="w-10 h-full pt-1"
        onClick={(e) => setShowAccountModal(true)}
      >
        <MdAccountCircle className="text-4xl mx-auto md:cursor-pointer" />
      </div>

      {showAccountModal && (
        <div className="fixed w-screen top-0 z-30 left-0 h-screen mx-auto bg-gray-300 bg-opacity-70 grid place-items-center">
          <div className="md:w-1/2  xl:w-1/4 bg-white border-2 rounded border-black p-5">
            <div className="text-right text-primary text-4xl">
              <AiOutlineClose
                className="inline mr-1 md:cursor-pointer"
                onClick={(e) => setShowAccountModal(false)}
              />
            </div>
            <div className="mt-5 text-2xl text-center text-black">
              You are logged in to
              <br />
              <span className="font-semibold text-black text-lg">
                {account.tenant.name}
              </span>
              <br />
              <span className="text-black">as</span>
              <br />
              <span className="font-semibold text-black text-lg">
                {account.email}
              </span>
              <br />
            </div>
            <div className="mt-5 text-center text-black">
              <p>Prefered language</p>
              <select
                className="mt-2 w-full border-2 rounded px-2"
                defaultValue={language}
                onChange={(e) => updatePreferredLanguage(e.target.value)}
              >
                {sortedLanguages.map((code, i) => {
                  return (
                    <option key={i} value={code}>
                      {languages[code].name}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className="flex gap-2 flex-col md:flex-row justify-center mt-10 text-center">
              <button
                className="rounded-xl p-1 px-7  text-white text-2xl font-semibold bg-primary cursor-pointer"
                onClick={() => history.push("/login/select-profile")}
              >
                Switch Account
              </button>
              <button
                className="rounded-xl p-1 px-7  text-white text-2xl font-semibold bg-primary"
                onClick={submitLogout}
              >
                Log out
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
