import React, { useEffect, useState } from "react"
import { FaAngleRight } from "react-icons/fa"
import { useHistory } from "react-router-dom"
import { useStore } from "react-context-hook"
import { evaluateQuestionDisplayConditions } from "../../utils"
import TranslationWrapper from "../utils/translationWrapper"

export const SectionListItem = (props) => {
  const [captureGroups, setCaptureGroups] = useStore("captureGroups", null)
  const [captureGroup, setCaptureGroup] = useStore(
    "captureGroup",
    captureGroups?.find((cg) => cg.unique_id === props.params.captureGroupId)
  )
  const [assessments, setAssessments] = useStore("assessments", null)
  const [assessment, setAssessment] = useStore(
    "assessment",
    assessments?.find((ass) => ass.unique_id === props.params.assessmentId)
  )

  const [answers, setAnswers] = useStore("answers", null)
  const [answeredQuestions, setAnsweredQuestions] = useState(0)
  const [hiddenQuestions, setHiddenQuestions] = useState(0)
  const history = useHistory()

  useEffect(() => {
    if (answers) {
      let answeredCount = 0
      let hiddenCount = 0
      if (answers[props.params.surveyId]) {
        answers[props.params.surveyId].schema.sections[props.id].questions
          .filter((q) => q.type !== "blank")
          .forEach((q) => {
            if (q.hasOwnProperty("answer")) {
              answeredCount++
            } else if (
              !evaluateQuestionDisplayConditions(
                q,
                answers,
                props.survey,
                assessment?.subject.species || captureGroup?.subject.species,
                props.id
              )
            ) {
              hiddenCount++
            }
          })
      }
      setHiddenQuestions(hiddenCount)
      setAnsweredQuestions(answeredCount)
    } else {
      return 0
    }
  }, [answers])

  return (
    <div className="p-3 border-b-2 border-gray-500 md:border md:rounded-lg md:mb-4">
      <div className="float-right">
        <div className="text-4xl p-1 rounded-full bg-primary text-white mt-1">
          {props.survey.capturing ? (
            <FaAngleRight
              className="md:cursor-pointer"
              onClick={() =>
                history.push(
                  `/capture-group/${props.params.captureGroupId}/survey/${
                    props.params.surveyId
                  }/section/${props.id + 1}`
                )
              }
            />
          ) : (
            <FaAngleRight
              className="md:cursor-pointer"
              onClick={() =>
                history.push(
                  `/assessment/${props.params.assessmentId}/survey/${
                    props.params.surveyId
                  }/section/${props.id + 1}`
                )
              }
            />
          )}
        </div>
      </div>
      <div className="text-2xl font-semibold">
        <TranslationWrapper
          translations={props.survey.survey_schema_translations}
        >
          {props.section.title}
        </TranslationWrapper>
      </div>
      <div className="text-gray-500">
        Questions answered &nbsp;
        <span
          className={`font-semibold ${
            answeredQuestions + hiddenQuestions >=
              props.section.questions.filter(
                (q) => q.required && q.type !== "blank"
              ).length && "text-green-600"
          }`}
        >
          {answeredQuestions} /{" "}
          {props.section.questions.filter((q) => q.type !== "blank").length -
            hiddenQuestions}
        </span>
      </div>
    </div>
  )
}
