import React, { useEffect, useState } from "react"
import { useStore } from "react-context-hook"
import { NavBar } from "../components/navbar"
import { AssessmentsListItem } from "../components/assessmentsListItem"
import { isObjEmpty } from "../../utils"

export const Assessments = () => {
  const [assessments, setAssessments] = useStore("assessments", [])
  const [filteredAssessments, setFilteredAssessments] = useState([])
  const [expandFilters, setExpandFilters] = useState(false)
  const [filters, setFilters] = useState({})

  useEffect(() => {
    setFilteredAssessments(assessments)
  }, [assessments])

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  const filterChange = (e, filter) => {
    let newFilters = { ...filters }
    if (e.target.value) newFilters[filter] = e.target.value
    else delete newFilters[filter]

    setFilters(newFilters)
    const assessments_ = isObjEmpty(newFilters)
      ? assessments
      : filterAssessments(newFilters)

    setFilteredAssessments(assessments_)
  }

  const filterAssessments = (filters) => {
    return assessments.filter((a) => {
      if (filters.name) {
        if (
          !a.subject.name
            .toLowerCase()
            .includes(filters.name.trim().toLowerCase())
        )
          return false
      }

      if (filters.business_name && a.subject.business_name) {
        if (
          !a.subject.business_name
            .toLowerCase()
            .includes(filters.business_name.toLowerCase())
        )
          return false
      } else if (!a.subject.business_name && filters.business_name) return false

      if (filters.code && a.subject.code) {
        if (
          !a.subject.code
            .toLowerCase()
            .includes(filters.code.trim().toLowerCase())
        )
          return false
      } else if (!a.subject.code && filters.code) return false

      if (filters.postcode) {
        if (
          !a.subject.postcode
            .toLowerCase()
            .replaceAll(" ", "")
            .includes(filters.postcode.toLowerCase().replaceAll(" ", ""))
        )
          return false
      }

      if (filters.supplier) {
        if (
          !a.authorizer.name
            .toLowerCase()
            .replaceAll(" ", "")
            .includes(filters.supplier.toLowerCase().replaceAll(" ", ""))
        )
          return false
      }

      return true
    })
  }

  return (
    <>
      <NavBar
        text={`${assessments.length} assessments pending`}
        iconLeft="back"
        iconRight="account"
        route="/"
      />
      <form
        onSubmit={handleSubmit}
        className="mx-auto md:w-1/2 lg:w-1/4 md:pt-5 border-b-2 md:border-0 border-gray-500 p-3"
      >
        <label htmlFor="">Search</label>
        <input
          onChange={(e) => filterChange(e, "name")}
          placeholder="Farm Name"
          className="border-2 border-gray-300 rounded p-2 w-full"
        />
        {expandFilters && (
          <>
            <input
              onChange={(e) => filterChange(e, "business_name")}
              placeholder="Company Name"
              className="border-2 border-gray-300 rounded mt-1 p-2 w-full"
            />
            <input
              onChange={(e) => filterChange(e, "code")}
              placeholder="Farm Code"
              className="border-2 border-gray-300 rounded p-2 mt-1 w-full"
            />
            <input
              onChange={(e) => filterChange(e, "postcode")}
              placeholder="Postcode"
              className="border-2 border-gray-300 rounded p-2 mt-1 w-full"
            />
            <input
              onChange={(e) => filterChange(e, "supplier")}
              placeholder="Supplier Name"
              className="border-2 border-gray-300 rounded p-2 mt-1 w-full"
            />
          </>
        )}
        <button
          onClick={() => setExpandFilters((c) => !c)}
          className="rounded my-2 p-2 w-full bg-primary text-white"
        >
          {expandFilters ? "Fewer" : "More"} Filters
        </button>
      </form>
      <div className="mx-auto md:w-1/2 lg:w-1/4 md:pt-2">
        {filteredAssessments.map((assessment) => (
          <AssessmentsListItem
            key={assessment.unique_id}
            assessment={assessment}
          />
        ))}
      </div>
    </>
  )
}
