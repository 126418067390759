import React, { useCallback, useState, useEffect, useRef } from "react"
import { FaCamera } from "react-icons/fa"
import {
  IoIosCloseCircleOutline,
  IoIosRadioButtonOff,
  IoIosSync,
  IoMdClose,
  IoMdCheckmark,
} from "react-icons/io"
import Webcam from "react-webcam"

export const Capture = ({
  handlePhotoSelect,
  externalButton = false,
  setExternalState = null,
}) => {
  const [imageSrc, setImageSrc] = useState(null)
  const [cameraError, setCameraError] = useState(false)
  const [loadingCamera, setLoadingCamera] = useState(true)
  const [cameraFacing, setCameraFacing] = useState("environment")
  const [cameraOn, setCameraOn] = useState(false)

  const webcam = useRef()

  const takePhoto = useCallback(() => {
    const imgSrc = webcam.current.getScreenshot()
    setImageSrc(imgSrc)
  })

  const reverseCamera = () => {
    setCameraFacing((curr) => {
      return curr === "user" ? "environment" : "user"
    })
  }

  const retryCamera = () => {
    setCameraOn(false)
    setExternalState && setExternalState(false)

    setTimeout(() => {
      setCameraOn(true)
      setExternalState && setExternalState(true)
    }, 1000)
  }

  const handlePhotoSelection = () => {
    setCameraOn(false)
    setExternalState && setExternalState(false)
    setLoadingCamera(true)
    handlePhotoSelect(imageSrc)
    setImageSrc(null)
  }

  return (
    <>
      <div className="text-center pt-1 overflow-y-hidden">
        {!externalButton && (
          <button
            className="rounded-xl p-1 px-12 pr-8 text-white text-3xl font-semibold bg-primary"
            onClick={() => {
              setCameraOn((curr) => !curr)
              setLoadingCamera(true)
            }}
          >
            Camera <FaCamera className="inline text-4xl" />
          </button>
        )}
        {!cameraError && (cameraOn || externalButton) && (
          <div className="z-20 inset-0 flex fixed m-auto min-h-screen bg-gray-100">
            {imageSrc && <img className="m-auto p-auto" src={imageSrc} />}
            <Webcam
              className={`m-auto my-auto z-20 p-auto ${
                imageSrc ? "hidden" : ""
              }`}
              videoConstraints={{ facingMode: cameraFacing }}
              onUserMedia={(e) => {
                setLoadingCamera(false)
              }}
              onUserMediaError={(e) => {
                setLoadingCamera(false)
                setCameraError(true)
              }}
              screenshotFormat="image/png"
              ref={webcam}
            />
            {loadingCamera ? (
              <div className="inset-0 fixed flex w-full h-12 justify-center m-auto bg-primary">
                <p className="text-white font-semibold m-auto">Loading...</p>
              </div>
            ) : imageSrc ? (
              <div className="flex absolute z-30 mb-5 bottom-0 p-2 m-auto justify-center w-full">
                <button
                  className="m-auto rounded-full text-white font-semibold bg-primary"
                  onClick={() => setImageSrc(null)}
                >
                  <IoMdClose className="inline text-7xl" />
                </button>
                <button
                  className="m-auto  rounded-full text-white font-semibold bg-primary"
                  onClick={handlePhotoSelection}
                >
                  <IoMdCheckmark className="inline text-7xl" />
                </button>
              </div>
            ) : (
              <div className="flex absolute z-30 mb-5 bottom-0 p-2 m-auto justify-center w-full">
                <button
                  className="ml-0 mr-auto rounded-full text-white font-semibold bg-primary"
                  onClick={() => {
                    setCameraOn(false)
                    setExternalState && setExternalState(false)
                  }}
                >
                  <IoIosCloseCircleOutline className="inline text-7xl" />
                </button>
                <button
                  className="mx-auto rounded-full text-white font-semibold bg-primary"
                  onClick={takePhoto}
                >
                  <IoIosRadioButtonOff className="inline text-7xl" />
                </button>
                <button
                  className="mr-0 ml-auto rounded-full text-white font-semibold bg-primary"
                  onClick={reverseCamera}
                >
                  <IoIosSync className="inline text-7xl" />
                </button>
              </div>
            )}
          </div>
        )}
        {cameraError && (
          <div className="bg-red-400 rounded-md font-extrabold w-4/5 m-auto p-3">
            There was an error loading the camera
            <br />
            <button
              onClick={retryCamera}
              className="bg-red-200 rounded-md m-auto mt-1 p-1 w-24 active:bg-red-50"
            >
              Retry
            </button>
          </div>
        )}
        <img src={imageSrc} />
      </div>
    </>
  )
}
