import React from "react"
import { withStore } from "react-context-hook"

import { Routes } from "./Routes"

const initState = {
  language: localStorage.getItem("language") || "en",
}

const App = () => {
  return <Routes />
}

export default withStore(App, initState)
