export const CommentBox = ({
  comment,
  setComment,
  placeholder = "Question comments...",
}) => {
  const handleCommentChange = (c) => {
    setComment(c)
  }

  return (
    <div className="text-left">
      <div className="flex py-2">
        <p className="mr-6">Comments:</p>
      </div>
      <textarea
        value={comment}
        onChange={(e) => handleCommentChange(e.target.value)}
        className="w-full border-2 rounded-lg border-gray-300 p-2"
        rows="2"
        placeholder={placeholder}
      />
    </div>
  )
}
