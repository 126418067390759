export const translateSurveyText = (
  targetLanguage,
  sourceText,
  surveyTranslations
) => {
  const targetLanguageTranslations = surveyTranslations?.find((t) => {
    return t.language === targetLanguage
  })
  if (targetLanguageTranslations) {
    let translations = targetLanguageTranslations.translations
    if (typeof translations !== "object") {
      try {
        translations = JSON.parse(translations)
      } catch (e) {
        console.error(e)
      }
    }
    if (translations.hasOwnProperty(sourceText)) {
      return translations[sourceText]
    }
  }
  return sourceText
}
