import React, { useEffect, useState } from "react"
import { useStoreValue } from "react-context-hook"
import { QuestionStatus } from "../saveQuestion"
import { translateSurveyText } from "../../../../utils/translate"

export const NumberQuestion = (props) => {
  const [answer, setAnswer] = useState(props.answer.number || "")
  const [unit, setUnit] = useState(
    props.answer.unit || props.question?.properties?.units?.[0] || ""
  )
  const [saved, setSaved] = useState(false)
  const [valid, setValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [warningMessage, setWarningMessage] = useState(null)
  const [saveButtonText, setSaveButtonText] = useState("Save")
  const [saveButtonColour, setSaveButtonColour] = useState("bg-primary")

  const lang = useStoreValue("language")

  useEffect(() => {
    if (props.answer) setSaved(true)
  }, [])

  const updateAnswer = (value) => {
    setAnswer(value)
    setSaved(false)
  }

  const updateUnit = (value) => {
    setUnit(value)
    setSaved(false)
  }

  const saveAnswer = () => {
    let validated = true
    setWarningMessage(null)

    if (answer === "") {
      validated = false
      setValid(false)
      setErrorMessage("You must enter a number.")
      setSaveButtonColour("bg-red-600")
      setSaveButtonText("Error!")

      props.removeFromAnswers()
      setSaved(false)
    }

    if (answer) {
      setValid(true)
      setErrorMessage(null)
      setSaveButtonColour("bg-green-600")
      setSaveButtonText("Saved!")

      if (+answer === +answer) {
        let numericAnswer = answer * 1

        // Handle soft limit validation
        if (
          props.question.properties?.softlimit_min ||
          props.question.properties?.softlimit_max
        ) {
          if (props.question.properties?.softlimit_min) {
            let numericSoftLimitMin =
              props.question.properties.softlimit_min * 1
            if (numericAnswer < numericSoftLimitMin) {
              setWarningMessage(
                `Warning: below soft limit of ${numericSoftLimitMin}`
              )
            }
          }

          if (props.question.properties?.softlimit_max) {
            let numericSoftLimitMax =
              props.question.properties.softlimit_max * 1
            if (numericAnswer > numericSoftLimitMax) {
              setWarningMessage(
                `Warning: above soft limit of ${numericSoftLimitMax}`
              )
            }
          }
        }

        // Handle hard limit validation
        if (
          props.question.properties?.hardlimit_min ||
          props.question.properties?.hardlimit_max
        ) {
          if (props.question.properties?.hardlimit_min) {
            let numericHardLimitMin =
              props.question.properties.hardlimit_min * 1
            if (numericAnswer < numericHardLimitMin) {
              validated = false
              setValid(false)
              setWarningMessage(null)
              setErrorMessage(
                `Error: below hard limit of ${numericHardLimitMin}`
              )
              setSaveButtonColour("bg-red-600")
              setSaveButtonText("Error!")

              props.removeFromAnswers()
              setSaved(false)
            }
          }

          if (props.question.properties?.hardlimit_max) {
            let numericHardLimitMax =
              props.question.properties.hardlimit_max * 1
            if (numericAnswer > numericHardLimitMax) {
              validated = false
              setValid(false)
              setWarningMessage(null)
              setErrorMessage(
                `Error: above hard limit of ${numericHardLimitMax}`
              )
              setSaveButtonColour("bg-red-600")
              setSaveButtonText("Error!")

              props.removeFromAnswers()
              setSaved(false)
            }
          }
        }
      } else {
        validated = false
        setValid(false)
        setWarningMessage(null)
        setErrorMessage(`You must enter a number.`)
        setSaveButtonColour("bg-red-600")
        setSaveButtonText("Error!")

        props.removeFromAnswers()
        setSaved(false)
      }
    }

    if (validated) {
      props.saveToAnswers({
        number: answer,
        unit: unit,
      })
      setSaved(true)
    }

    setTimeout(() => {
      setSaveButtonColour("bg-primary")
      setSaveButtonText("Save")
    }, 1000)
  }

  return (
    <div>
      <input
        type="text"
        name={`q_${props.question.id}`}
        placeholder="Enter a number..."
        className={`w-2/3 outline-none border-2 border-${
          valid ? "gray" : "red"
        }-300 focus:border-2 focus:border-yellow-500 focus:rounded rounded-lg p-2 text-left`}
        value={answer}
        onChange={(e) => {
          updateAnswer(e.target.value)
        }}
        autoComplete="off"
      />
      {props.question.properties?.units?.length > 0 && (
        <select
          className={`h-11 pr-5 ml-1 md:w-auto outline-none border-2 border-gray-300 rounded-lg focus:border-2 focus:border-yellow-500 focus:rounded`}
          value={unit}
          onChange={(e) => {
            updateUnit(e.target.value)
          }}
        >
          {props.question.properties.units.map((u, i) => (
            <option key={i} value={u}>
              {translateSurveyText(
                lang,
                u,
                props.survey.survey_schema_translations
              )}
            </option>
          ))}
        </select>
      )}
      <div className="mt-2 text-gray-400">{warningMessage}</div>
      {/* {true && 
        <Conformance saveConformance={props.saveConformance}/>
        } */}
      <QuestionStatus
        question={props.question}
        answer={answer}
        saveAnswer={saveAnswer}
        saved={saved}
        saveButtonColour={saveButtonColour}
        setSaveButtonColour={setSaveButtonColour}
        saveButtonText={saveButtonText}
        setSaveButtonText={setSaveButtonText}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        comment={props.comment}
        saveComment={props.saveComment}
        conformance={props.conformance}
        saveConformance={props.saveConformance}
      />
    </div>
  )
}
