import React, { useEffect, useState } from "react"
import { useStore } from "react-context-hook"
import { Header } from "../components/header"
import { NavBar } from "../components/navbar"
import { CaptureGroupListItem } from "../components/captureGroupListItem"
import { isObjEmpty } from "../../utils"
import DataLoadingContainer from "../utils/loader/DataLoadingContainer"

export const CaptureGroups = () => {
  const [captureGroups, setCaptureGroups] = useStore("captureGroups", [])
  const [filteredCaptureGroups, setFilteredCaptureGroups] = useState([])
  const [expandFilters, setExpandFilters] = useState(false)
  const [filters, setFilters] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    setFilteredCaptureGroups(captureGroups)
    setIsLoading(false)
  }, [captureGroups])

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  const filterChange = (e, filter) => {
    let filters_ = filters
    if (e.target.value) filters_[filter] = e.target.value
    else delete filters[filter]

    setFilters(filters_)
    const captureGroups_ = isObjEmpty(filters_)
      ? captureGroups
      : filterCaptureGroups(filters_)

    setFilteredCaptureGroups(captureGroups_)
  }

  const filterCaptureGroups = (filters) => {
    const capture_groups = captureGroups.filter((cg) => {
      if (filters.name) {
        if (cg.subject.name.toLowerCase().includes(filters.name.toLowerCase()))
          return true
      }

      if (filters.business_name && cg.subject.business_name) {
        if (
          cg.subject.business_name
            .toLowerCase()
            .includes(filters.business_name.toLowerCase())
        )
          return true
      }

      if (filters.code && cg.subject.code) {
        if (cg.subject.code.toLowerCase().includes(filters.code.toLowerCase()))
          return true
      } else if (filters.code) return false

      if (filters.postcode) {
        if (
          cg.subject.postcode
            .toLowerCase()
            .replaceAll(" ", "")
            .includes(filters.postcode.toLowerCase().replaceAll(" ", ""))
        )
          return true
      }

      if (filters.supplier) {
        if (
          cg.authorizer.name
            .toLowerCase()
            .replaceAll(" ", "")
            .includes(filters.supplier.toLowerCase().replaceAll(" ", ""))
        )
          return true
      }

      if (Object.keys(filters).length <= 0) return true

      return false
    })

    return capture_groups
  }

  return (
    <>
      {/* <Header /> */}
      <NavBar
        text={`${captureGroups.length} Farms Active`}
        iconLeft="back"
        iconRight="account"
        route="/"
      />
      <DataLoadingContainer loading={isLoading}>
        <form
          onSubmit={handleSubmit}
          className="mx-auto md:w-1/2 lg:w-1/4 md:pt-5 border-b-2 md:border-0 border-gray-500 p-3"
        >
          <label htmlFor="">Search</label>
          <input
            onChange={(e) => filterChange(e, "name")}
            placeholder="Farm Name"
            className="border-2 border-gray-300 rounded p-2 w-full"
          />
          {expandFilters && (
            <>
              <input
                onChange={(e) => filterChange(e, "business_name")}
                placeholder="Company Name"
                className="border-2 border-gray-300 rounded p-2 mt-1 w-full"
              />
              <input
                onChange={(e) => filterChange(e, "code")}
                placeholder="Farm Code"
                className="border-2 border-gray-300 rounded p-2 mt-1 w-full"
              />
              <input
                onChange={(e) => filterChange(e, "postcode")}
                placeholder="Postcode"
                className="border-2 border-gray-300 rounded p-2 mt-1 w-full"
              />
              <input
                onChange={(e) => filterChange(e, "supplier")}
                placeholder="Supplier Name"
                className="border-2 border-gray-300 rounded p-2 mt-1 w-full"
              />
            </>
          )}
          <button
            onClick={() => setExpandFilters((c) => !c)}
            className="rounded my-2 p-2 w-full bg-primary text-white"
          >
            {expandFilters ? "Fewer" : "More"} Filters
          </button>
          {/* <p onClick={()=>setExpandFilters(c => !c)} className="text-right text-sm cursor-pointer text-gray-500 pb-2">{expandFilters ? "Fewer" : "More"} filters...</p> */}
        </form>
        <div className="mx-auto md:w-1/2 lg:w-1/4 md:pt-2">
          {filteredCaptureGroups.map((capture_group) => (
            <CaptureGroupListItem
              key={capture_group.unique_id}
              captureGroup={capture_group}
            />
          ))}
        </div>
      </DataLoadingContainer>
    </>
  )
}
