import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useStore } from "react-context-hook"
import { Header } from "../components/header"
import { NavBar } from "../components/navbar"
import { FaAngleRight } from "react-icons/fa"
import DataLoadingContainer from "../utils/loader/DataLoadingContainer"

const ROLES_MAP = {
  admin: "Admin",
  retailer: "Retailer",
  supplier: "Supplier",
  cb_manager: "CB Manager",
  assessment_trainer: "Assessor Trainer",
  assessor: "Assessor",
  farmer: "Farmer",
}

export const getUserProfiles = async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
    credentials: "include",
  }

  return await fetch(
    `${process.env.REACT_APP_API_HOST}/auth/user-profiles`,
    requestOptions
  )
    .then((res) => res.json())
    .catch((err) => console.error(err))
}

export const Login = () => {
  const [stage, setStage] = useState(0)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [authData, setAuthData] = useState(null)
  const [profileOptions, setProfileOptions] = useState()

  const [isLoading, setIsLoading] = useState(true)

  const [answers, setAnswers] = useStore("answers", null)
  const [assessments, setAssessments] = useStore("assessments", null)
  const [captureGroups, setCaptureGroups] = useStore("captureGroups", null)
  const [surveys, setSurveys] = useStore("surveys", null)
  const [speciesConfig, setSpeciesConfig] = useStore("speciesConfig", null)
  const [appConfig, setAppConfig] = useStore("appConfig", null)
  const [branding, setBranding] = useStore("branding", null)
  const [translationIds, setTranslationIds] = useStore("translationIds", null)

  const history = useHistory()
  const params = useParams()

  const submitLogin = () => {
    setIsLoading(true)

    fetch(`${process.env.REACT_APP_API_HOST}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
      redirect: "follow",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false)
        let passwordElement = document.querySelector("input[name=password]")
        if (res.status !== 200) {
          if (
            res.message === "No user found with that email address" ||
            res.message === "User found but password is incorrect"
          ) {
            passwordElement.value = ""
            passwordElement.classList.add("border-red-400")
            setPassword("")
            setError("The email or password is not recognised")
          } else {
            passwordElement.classList.remove("border-red-400")
            setError(res.message)
          }
        } else if (res.status === 200) {
          passwordElement.classList.remove("border-red-400")
          setAuthData(res.data)
          setError("")
          if (res.data?.profiles?.length === 1) {
            selectProfile(res.data.profiles[0])
          } else {
            setIsLoading(false)
            setStage(1)
          }
        }
      })
      .catch((error) => {
        setError("Unable to login. Please check your internet connection.")
      })
  }

  const selectProfile = async (profile) => {
    setIsLoading(true)
    if (!profile) return
    const myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")

    const jsonData = JSON.stringify({
      email,
      password,
      profile: {
        email: profile.email,
        unique_id: profile.unique_id,
        tenant: profile.tenantLabel,
      },
    })

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: jsonData,
      redirect: "follow",
      credentials: "include",
    }

    return await fetch(
      `${process.env.REACT_APP_API_HOST}/auth/select-profile`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 400) {
          setError(data.message)
        } else if (data.status === 200) {
          // nullify context stores so they will be
          // refetched when new tenant is selected
          // (in Routes.js)
          setAnswers(null)
          setAssessments(null)
          setCaptureGroups(null)
          setAppConfig(null)
          setSpeciesConfig(null)
          setBranding(null)
          setSurveys(null)
          setTranslationIds(null)

          history.push("/")
        }
      })
      .catch((err) => {
        setError("Unable to login. Please check your internet connection.")
        console.error(err)
      })
  }

  useEffect(() => {
    if (params.selectProfile) {
      getUserProfiles().then((res) => {
        const options = res.data?.reduce((prev, curr) => {
          if (!["assessor", "admin"].includes(curr.role)) return prev
          prev[curr.tenantLabel] = [...(prev[curr.tenantLabel] || []), curr]
          return prev
        }, {})
        setProfileOptions(options)
        setStage(1)
        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (authData?.profiles) {
      const options = authData.profiles?.reduce((prev, curr) => {
        if (!["assessor", "admin"].includes(curr.role)) return prev
        prev[curr.tenantLabel] = [...(prev[curr.tenantLabel] || []), curr]
        return prev
      }, {})
      setProfileOptions(options)
    }
  }, [authData])

  return (
    <>
      {/* <Header /> */}
      <NavBar text="Login to account" />
      <DataLoadingContainer loading={isLoading}>
        {stage === 0 && (
          <div className="max-w-lg mx-auto p-5 mt-5">
            <div className="mb-5 text-lg text-center">
              <p className="font-semibold text-gray-600">
                Please enter your email and password to login to your account.
              </p>
            </div>
            <form onSubmit={submitLogin}>
              <div className="mb-5">
                <label className="text-lg text-gray-400 font-semibold">
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  required
                  className="border border-gray-400 rounded p-2 w-full"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-5">
                <label className="text-lg text-gray-400 font-semibold">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  required
                  className="border border-gray-400 rounded p-2 w-full"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="mb-5 text-center">
                <p className="text-red-600">{error}</p>
              </div>
              <div className="mt-10 text-center">
                <button
                  type="submit"
                  className={
                    "rounded-xl p-2 px-6 pr-4 text-white text-3xl font-semibold " +
                    (!email || !password ? "bg-gray-200" : "bg-primary")
                  }
                >
                  Login
                  <FaAngleRight className="inline text-4xl" />
                </button>
              </div>
            </form>
          </div>
        )}
        {stage === 1 && profileOptions && (
          <div className="max-w-lg mx-auto p-5 mt-5">
            <div className="mb-5 text-lg text-center">
              <p className="font-semibold text-gray-600 text-2xl">
                Please select a profile
              </p>
            </div>
            <div className="mb-5 text-center">
              {profileOptions &&
                Object.keys(profileOptions).map((key) => {
                  const profiles = profileOptions[key]
                  return (
                    <>
                      <div className="py-3">
                        <p className="font-semibold text-gray-600 text-xl">
                          {profiles[0]?.tenantName}
                        </p>
                        {profiles.map((profile, idx) => (
                          <button
                            key={idx}
                            className="w-full block rounded-xl bg-primary p-2 px-6 pr-4 text-white font-semibold text-lg mx-auto mt-3"
                            onClick={() => selectProfile(profile)}
                          >
                            {ROLES_MAP[profile.role]} | {profile.email}
                          </button>
                        ))}
                      </div>
                    </>
                  )
                })}
            </div>
            <div className="mb-5 text-center">
              <p className="text-red-600">{error}</p>
            </div>
          </div>
        )}
      </DataLoadingContainer>
    </>
  )
}
