import React, { useEffect, useState } from "react"
import { QuestionStatus } from "../saveQuestion"

export const TextQuestion = (props) => {
  const [answer, setAnswer] = useState(props.answer || "")
  const [conformance, setConformance] = useState(props.conformance)
  const [saved, setSaved] = useState(false)
  const [valid, setValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [saveButtonText, setSaveButtonText] = useState("Save")
  const [saveButtonColour, setSaveButtonColour] = useState("bg-primary")

  useEffect(() => {
    if (props.answer) setSaved(true)
  }, [])

  const updateAnswer = (value) => {
    setAnswer(value)
    setSaved(false)
  }

  const saveAnswer = () => {
    if (answer === "") {
      setValid(false)
      setErrorMessage("You must enter an answer.")
      setSaveButtonColour("bg-red-600")
      setSaveButtonText("Error!")

      props.removeFromAnswers()
      setSaved(false)
    } else {
      setValid(true)
      setErrorMessage(null)
      setSaveButtonColour("bg-green-600")
      setSaveButtonText("Saved!")

      props.saveToAnswers(answer)
      setSaved(true)
    }

    setTimeout(() => {
      setSaveButtonColour("bg-primary")
      setSaveButtonText("Save")
    }, 1000)
  }

  return (
    <div>
      <input
        type="text"
        placeholder="Type your answer..."
        className={`w-full outline-none border-2 border-${
          valid ? "gray" : "red"
        }-300 focus:border-2 focus:border-yellow-500 focus:rounded rounded-lg p-2 text-left`}
        value={answer}
        onChange={(e) => {
          updateAnswer(e.target.value)
        }}
        autoComplete="off"
      />
      <QuestionStatus
        question={props.question}
        answer={answer}
        saveAnswer={saveAnswer}
        saved={saved}
        saveButtonColour={saveButtonColour}
        setSaveButtonColour={setSaveButtonColour}
        saveButtonText={saveButtonText}
        setSaveButtonText={setSaveButtonText}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        comment={props.comment}
        saveComment={props.saveComment}
        conformance={conformance}
        saveConformance={props.saveConformance}
      />
    </div>
  )
}
