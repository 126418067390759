// import { Button } from "@wrappers"
import { useHistory } from "react-router-dom"
import Forbidden from "../assets/images/icons/forbidden.png"
import { Header } from "./components/header"
import { NavBar } from "./components/navbar"

const Unauthorized = () => {
  const history = useHistory()
  return (
    <>
      {/* <Header /> */}
      <NavBar text={`Unauthorised`} />
      <div className="w-full flex flex-col justify-center items-center">
        <img className="w-52 m-10" src={Forbidden} alt="Forbidden" />
        <h3>You are not authorised to view this page</h3>

        <button
          className="mt-8 rounded-xl p-1 px-8 text-white text-2xl font-semibold bg-secondary"
          onClick={() => history.push("/login")}
        >
          Back
        </button>
      </div>
    </>
  )
}
export default Unauthorized
