import React, { useEffect, useState } from "react"
import { QuestionStatus } from "../saveQuestion"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

export const DateQuestion = (props) => {
  const [answer, setAnswer] = useState(() => {
    if (!props.answer) return null
    const date = new Date(props.answer)
    if (!(date instanceof Date)) return null
    if (isNaN(date.getTime())) return null
    return date
  })
  const [datePickerType, setDatePickerType] = useState("any")
  const [saved, setSaved] = useState(false)
  const [valid, setValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [saveButtonText, setSaveButtonText] = useState("Save")
  const [saveButtonColour, setSaveButtonColour] = useState("bg-primary")

  useEffect(() => {
    if (props.answer) setSaved(true)
    determineDatePickerType()
  }, [])

  const determineDatePickerType = () => {
    let type = "any"
    const {
      past_limit_min,
      past_limit_max,
      future_limit_min,
      future_limit_max,
    } = props.question.properties

    if (
      past_limit_max ||
      past_limit_min ||
      future_limit_max ||
      future_limit_min
    ) {
      type = "range"
    }

    if (
      (past_limit_max && past_limit_min) ||
      (future_limit_max && future_limit_min)
    ) {
      type = "included"
    }

    setDatePickerType(type)
  }

  const updateAnswer = (value) => {
    setAnswer(value)
    setSaved(false)
  }

  const renderDatePicker = () => {
    const datePickerClasses =
      "w-full outline-none border-2 border-gray-300 rounded-lg p-2 text-left focus:border-2 focus:border-yellow-500 focus:rounded"
    const datePickerFormat = "dd/MM/yyyy"
    const datePickerPlaceholder = "Click here to pick a date..."
    const {
      past_limit_min,
      past_limit_max,
      future_limit_min,
      future_limit_max,
    } = props.question.properties

    if (datePickerType === "any") {
      return (
        <DatePicker
          selected={answer}
          onChange={(date) => updateAnswer(date)}
          dateFormat={datePickerFormat}
          className={datePickerClasses}
          placeholderText={datePickerPlaceholder}
        />
      )
    }

    if (datePickerType == "range") {
      let pastDays = 0
      if (past_limit_min) pastDays = -past_limit_min
      if (past_limit_max) pastDays = -past_limit_max
      const pastDate = new Date()
      pastDate.setDate(pastDate.getDate() + pastDays)

      let futureDays = 0
      if (future_limit_min) futureDays = +future_limit_min
      if (future_limit_max) futureDays = +future_limit_max
      const futureDate = new Date()
      futureDate.setDate(futureDate.getDate() + futureDays)

      return (
        <DatePicker
          selected={answer}
          onChange={(date) => updateAnswer(date)}
          dateFormat={datePickerFormat}
          minDate={pastDate}
          maxDate={futureDate}
          className={datePickerClasses}
          placeholderText={datePickerPlaceholder}
        />
      )
    }

    if (datePickerType === "included") {
      const arrayOfDates = []

      if (past_limit_min) {
        for (let x = -past_limit_min; x >= -past_limit_max; x--) {
          const theDateToAdd = new Date()
          theDateToAdd.setDate(theDateToAdd.getDate() + x)
          arrayOfDates.push(theDateToAdd)
        }
      }

      if (future_limit_min) {
        for (let x = +future_limit_min; x <= +future_limit_max; x++) {
          const theDateToAdd = new Date()
          theDateToAdd.setDate(theDateToAdd.getDate() + x)
          arrayOfDates.push(theDateToAdd)
        }
      }

      return (
        <DatePicker
          selected={answer}
          onChange={(date) => updateAnswer(date)}
          dateFormat={datePickerFormat}
          className={datePickerClasses}
          placeholderText={datePickerPlaceholder}
          includeDates={arrayOfDates}
        />
      )
    }
  }

  const saveAnswer = () => {
    if (answer === "") {
      setValid(false)
      setErrorMessage("You must enter a date.")
      setSaveButtonColour("bg-red-600")
      setSaveButtonText("Error!")

      props.removeFromAnswers()
      setSaved(false)
    } else {
      setValid(true)
      setErrorMessage(null)
      setSaveButtonColour("bg-green-600")
      setSaveButtonText("Saved!")

      props.saveToAnswers(answer)
      setSaved(true)
    }

    setTimeout(() => {
      setSaveButtonColour("bg-primary")
      setSaveButtonText("Save")
    }, 1000)
  }

  return (
    <div>
      {renderDatePicker()}
      <QuestionStatus
        question={props.question}
        answer={answer}
        saveAnswer={saveAnswer}
        saved={saved}
        saveButtonColour={saveButtonColour}
        setSaveButtonColour={setSaveButtonColour}
        saveButtonText={saveButtonText}
        setSaveButtonText={setSaveButtonText}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        comment={props.comment}
        saveComment={props.saveComment}
        conformance={props.conformance}
        saveConformance={props.saveConformance}
      />
    </div>
  )
}
