import React, { useEffect, useState } from "react"
import { useStore } from "react-context-hook"
import { evaluateQuestionDisplayConditions } from "../../utils"
import { Header } from "../components/header"
import { NavBar } from "../components/navbar"
import { Question } from "../components/questions/question"
import { useHistory } from "react-router-dom"
import TranslationWrapper from "../utils/translationWrapper"

export const Section = (props) => {
  const [captureGroups, setCaptureGroups] = useStore("captureGroups", null)
  const [captureGroup, setCaptureGroup] = useStore(
    "captureGroup",
    captureGroups?.find(
      (cg) => cg.unique_id === props.match.params.captureGroupId
    )
  )
  const [assessments, setAssessments] = useStore("assessments", null)
  const [assessment, setAssessment] = useStore(
    "assessment",
    assessments?.find(
      (ass) => ass.unique_id === props.match.params.assessmentId
    )
  )

  const [surveys, setSurveys] = useStore("surveys", null)
  const [survey, setSurvey] = useStore("survey", null)
  const [section, setSection] = useState([])
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useStore("answers", null)
  const [pendingQuestionCount, setPendingQuestionCount] = useState(null)
  const [showSubmitButton, setShowSubmitButton] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (surveys) {
      const index = props.match.params.sectionId - 1
      const thisSurvey = surveys.filter(
        (survey) => survey.unique_id === props.match.params.surveyId
      )[0]
      const sections = thisSurvey.schema.sections
      const section = sections[index]
      setSurvey(thisSurvey)
      setSection(section)
      setQuestions(section.questions)
    }
  }, [surveys])

  useEffect(() => {
    let pendingCount = getPendingQuestionsCount()

    if (pendingCount === 0 && questions.length > 0) {
      setShowSubmitButton(true)
    } else {
      setShowSubmitButton(false)
    }
    setPendingQuestionCount(pendingCount)
  }, [questions, answers])

  const getPendingQuestionsCount = () => {
    if (answers && questions.length > 0) {
      const index = props.match.params.sectionId - 1
      let answeredCount = 0
      if (answers[props.match.params.surveyId]) {
        answers[props.match.params.surveyId].schema.sections[index].questions
          .filter((q) => q.type !== "blank")
          .forEach((q) => {
            if (
              q.hasOwnProperty("answer") ||
              !q.required ||
              !evaluateQuestionDisplayConditions(
                q,
                answers,
                survey,
                assessment?.subject.species || captureGroup?.subject.species,
                index
              )
            ) {
              answeredCount++
            }
          })
      }
      return questions.filter((q) => q.type !== "blank").length - answeredCount
    } else {
      return questions.filter((q) => {
        return (
          q.type !== "blank" &&
          evaluateQuestionDisplayConditions(
            q,
            answers,
            survey,
            assessment?.subject.species || captureGroup?.subject.species,
            props.match.params.sectionId - 1
          )
        )
      }).length
    }
  }

  const getPendingQuestionsCountForAll = (surveyArr) => {
    let completedArr = []

    if (answers && surveyArr && surveyArr.length > 0) {
      for (let i = 0; i < surveyArr.length; i++) {
        const currentQuestions = surveyArr[i].questions
        let answeredCount = 0
        if (answers[props.match.params.surveyId]) {
          currentQuestions
            .filter((q) => q.type !== "blank")
            .forEach((q) => {
              if (
                q.hasOwnProperty("answer") ||
                !q.required ||
                !evaluateQuestionDisplayConditions(
                  q,
                  answers,
                  survey,
                  assessment?.subject.species || captureGroup?.subject.species,
                  i
                )
              ) {
                answeredCount++
              }
            })
        }

        currentQuestions.filter((q) => q.type !== "blank").length -
          answeredCount ===
        0
          ? completedArr.push(true)
          : completedArr.push(false)
      }
    }
    return completedArr
  }

  const getCorrectUrl = () => {
    const path = props.match.path

    const surveyStatus = getPendingQuestionsCountForAll(
      answers[props.match.params.surveyId]?.schema?.sections
    )
    if (path.includes("/capture-group")) {
      if (surveyStatus.includes(false)) {
        history.push(
          `/capture-group/${props.match.params.captureGroupId}/survey/${props.match.params.surveyId}`
        )
      } else {
        history.push(`/capture-group/${props.match.params.captureGroupId}`)
      }
    }

    if (path.includes("/assessment")) {
      if (surveyStatus.includes(false)) {
        history.push(
          `/assessment/${props.match.params.assessmentId}/survey/${props.match.params.surveyId}`
        )
      } else {
        history.push(`/assessment/${props.match.params.assessmentId}`)
      }
    }
  }

  return (
    <>
      <NavBar
        text={
          pendingQuestionCount === 0
            ? `All questions answered`
            : `${pendingQuestionCount} questions pending`
        }
        iconLeft="back"
        route={`/${survey?.capturing ? "capture-group" : "assessment"}/${
          props.match.params.assessmentId || props.match.params.captureGroupId
        }/survey/${props.match.params.surveyId}`}
      />
      <div className="mt-2 mb-2 p-4 text-gray-500 text-center">
        <div className="font-semibold text-xl text-gray-800">
          <TranslationWrapper translations={survey?.survey_schema_translations}>
            {section.title}
          </TranslationWrapper>
        </div>
        <div className="text-sm">
          Answer all of the questions in this survey section.
        </div>
      </div>
      <div className="mx-auto border-t-2 border-gray-500 md:border-t-0 md:w-1/2 lg:w-1/4 md:pt-5">
        {questions.length > 0 &&
          questions.map((question, index) => {
            const question_number =
              questions
                .filter((q) => {
                  return (
                    q.type !== "blank" &&
                    evaluateQuestionDisplayConditions(
                      q,
                      answers,
                      survey,
                      assessment?.subject.species ||
                        captureGroup?.subject.species,
                      props.match.params.sectionId - 1
                    )
                  )
                })
                .findIndex((q) => q.id === question.id) + 1
            return (
              <Question
                key={question.id}
                index={index}
                question={question}
                questions={questions}
                question_number={question_number}
                params={props.match.params}
              />
            )
          })}
        {showSubmitButton && (
          <button
            className="rounded my-2 p-2 mb-8 w-full bg-green-600 text-white"
            onClick={() => getCorrectUrl()}
          >
            Save
          </button>
        )}
      </div>
    </>
  )
}
