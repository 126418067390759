import React, { useEffect, useState } from "react"
import { useStore } from "react-context-hook"
import { evaluateQuestionDisplayConditions } from "../../../utils"
import { FaFile } from "react-icons/fa"
import {
  TextQuestion,
  ListQuestion,
  DropdownQuestion,
  NumberQuestion,
  DateQuestion,
  CalculationQuestion,
  MultiselectQuestion,
  YearQuestion,
  BooleanQuestion,
  FormulaQuestion,
  DistributionQuestion,
} from "../../components/questions/question_types/index"
import { Upload } from "../file_uploads"
import TranslationWrapper from "../../utils/translationWrapper"

export const Question = (props) => {
  const [captureGroups, setCaptureGroups] = useStore("captureGroups", null)
  const [captureGroup, setCaptureGroup] = useStore(
    "captureGroup",
    captureGroups?.find((cg) => cg.unique_id === props.params.captureGroupId)
  )
  const [assessments, setAssessments] = useStore("assessments", null)
  const [assessment, setAssessment] = useStore(
    "assessment",
    assessments?.find((ass) => ass.unique_id === props.params.assessmentId)
  )

  const [survey, setSurvey] = useStore("survey", null)
  const [answers, setAnswers] = useStore("answers", null)
  const [answered, setAnswered] = useState(null)
  const [collapsed, setCollapsed] = useState(false)
  const [clamped, setClamped] = useState(true)
  const [files, setFiles] = useState(null)
  const [questionMeetsDeps, setQuestionMeetsDeps] = useState(false)

  const questionTags = {
    text: TextQuestion,
    list: ListQuestion,
    dropdown: DropdownQuestion,
    number: NumberQuestion,
    date: DateQuestion,
    calculation: CalculationQuestion,
    multiselect: MultiselectQuestion,
    year: YearQuestion,
    boolean: BooleanQuestion,
    formula: FormulaQuestion,
    distribution: DistributionQuestion,
  }

  const QuestionTag = questionTags[props.question.type] || false

  const saveToAnswers = (answerObject) => {
    const copyOfAnswers = answers ? { ...answers } : {}
    const copyOfSurvey = { ...survey }
    const surveyUniqueID = copyOfSurvey.unique_id
    const sectionIndex = props.params.sectionId - 1

    if (copyOfAnswers[surveyUniqueID]) {
      copyOfAnswers[surveyUniqueID].schema.sections[
        sectionIndex
      ].questions.find((q) => q.id === props.question.id).answer = answerObject
    } else {
      copyOfSurvey.schema.sections[sectionIndex].questions.find(
        (q) => q.id === props.question.id
      ).answer = answerObject
      copyOfAnswers[surveyUniqueID] = copyOfSurvey
    }

    localStorage.setItem("answers", JSON.stringify(copyOfAnswers))
    setAnswers(copyOfAnswers)
    setAnswered(true)
    setCollapsed(true)
  }

  const saveComment = (comment) => {
    const copyOfAnswers = answers ? { ...answers } : {}
    const copyOfSurvey = { ...survey }
    const surveyUniqueID = copyOfSurvey.unique_id
    const sectionIndex = props.params.sectionId - 1

    if (copyOfAnswers[surveyUniqueID]) {
      copyOfAnswers[surveyUniqueID].schema.sections[
        sectionIndex
      ].questions.find((q) => q.id === props.question.id).comment = comment
    } else {
      copyOfSurvey.schema.sections[sectionIndex].questions.find(
        (q) => q.id === props.question.id
      ).comment = comment
      copyOfAnswers[surveyUniqueID] = copyOfSurvey
    }

    localStorage.setItem("answers", JSON.stringify(copyOfAnswers))
    setAnswers(copyOfAnswers)
  }

  const saveConformance = (conformance) => {
    const copyOfAnswers = answers ? { ...answers } : {}
    const copyOfSurvey = { ...survey }
    const surveyUniqueID = copyOfSurvey.unique_id
    const sectionIndex = props.params.sectionId - 1

    if (JSON.parse(conformance.conformant) && conformance.comment)
      conformance.comment = ""

    if (copyOfAnswers[surveyUniqueID]) {
      copyOfAnswers[surveyUniqueID].schema.sections[
        sectionIndex
      ].questions.find((q) => q.id === props.question.id).conformance =
        conformance
    } else {
      copyOfSurvey.schema.sections[sectionIndex].questions.find(
        (q) => q.id === props.question.id
      ).conformance = conformance
      copyOfAnswers[surveyUniqueID] = copyOfSurvey
    }

    localStorage.setItem("answers", JSON.stringify(copyOfAnswers))
    setAnswers(copyOfAnswers)
  }

  const getFromAnswers = () => {
    const surveyUniqueID = survey.unique_id
    const sectionIndex = props.params.sectionId - 1

    if (
      answers &&
      answers[surveyUniqueID] &&
      answers[surveyUniqueID].schema.sections[sectionIndex].questions
        .find((q) => q.id === props.question.id)
        .hasOwnProperty("answer")
    ) {
      return answers[surveyUniqueID].schema.sections[
        sectionIndex
      ].questions.find((q) => q.id === props.question.id).answer
    }
    return false
  }

  const getComment = () => {
    const surveyUniqueID = survey.unique_id
    const sectionIndex = props.params.sectionId - 1

    if (
      answers &&
      answers[surveyUniqueID] &&
      answers[surveyUniqueID].schema.sections[sectionIndex].questions
        .find((q) => q.id === props.question.id)
        .hasOwnProperty("comment")
    ) {
      return answers[surveyUniqueID].schema.sections[
        sectionIndex
      ].questions.find((q) => q.id === props.question.id).comment
    }
    return false
  }

  const getConformance = () => {
    const surveyUniqueID = survey.unique_id
    const sectionIndex = props.params.sectionId - 1

    if (
      answers &&
      answers[surveyUniqueID] &&
      answers[surveyUniqueID].schema.sections[sectionIndex].questions
        .find((q) => q.id === props.question.id)
        .hasOwnProperty("conformance")
    ) {
      return answers[surveyUniqueID].schema.sections[
        sectionIndex
      ].questions.find((q) => q.id === props.question.id).conformance
    }
    return false
  }

  useEffect(() => {
    const surveyUniqueID = survey.unique_id
    const sectionIndex = props.params.sectionId - 1
    if (
      answers &&
      answers[surveyUniqueID] &&
      answers[surveyUniqueID].schema.sections[sectionIndex].questions.find(
        (q) => q.id === props.question.id
      ).answer
    ) {
      setAnswered(true)
      setCollapsed(true)
    }
    if (
      answers &&
      answers[surveyUniqueID] &&
      answers[surveyUniqueID].schema.sections[sectionIndex].questions
        .find((q) => q.id === props.question.id)
        .hasOwnProperty("files")
    )
      setFiles(
        answers[surveyUniqueID].schema.sections[sectionIndex].questions.find(
          (q) => q.id === props.question.id
        ).files
      )
  }, [])

  const removeFromAnswers = () => {
    const surveyUniqueID = survey.unique_id
    const sectionIndex = props.params.sectionId - 1

    if (answers) {
      if (answers[surveyUniqueID]) {
        if (
          answers[surveyUniqueID].schema.sections[sectionIndex].questions
            .find((q) => q.id === props.question.id)
            .hasOwnProperty("answer")
        ) {
          const copyOfAnswers = { ...answers }
          delete copyOfAnswers[surveyUniqueID].schema.sections[
            sectionIndex
          ].questions.find((q) => q.id === props.question.id).answer
          localStorage.setItem("answers", JSON.stringify(copyOfAnswers))
          setAnswers(copyOfAnswers)
          setAnswered(false)
        }
      }
    }
  }

  // Handle syncing of local storage object's reference to files
  // and files info state to display them
  const onChangeFiles = (newFiles) => {
    const copyOfAnswers = answers ? { ...answers } : {}
    const copyOfSurvey = { ...survey }
    const surveyUniqueID = survey.unique_id
    const sectionIndex = props.params.sectionId - 1

    if (newFiles && Array.isArray(newFiles)) {
      const filesMeta = newFiles.map((f) => ({
        name: f.name,
        size: f.size,
        type: f.type,
        unique_id: f.unique_id,
      }))
      if (copyOfAnswers[surveyUniqueID]) {
        copyOfAnswers[surveyUniqueID].schema.sections[
          sectionIndex
        ].questions.find((q) => q.id === props.question.id).files = filesMeta
      } else {
        copyOfSurvey.schema.sections[sectionIndex].questions.find(
          (q) => q.id === props.question.id
        ).files = filesMeta
        copyOfAnswers[surveyUniqueID] = copyOfSurvey
      }
      setFiles(filesMeta)
    }

    localStorage.setItem("answers", JSON.stringify(copyOfAnswers))
    setAnswers(copyOfAnswers)
  }

  // Handles deleting of file references on local storage object
  // and state to update display
  const onDeleteFile = (deletedFile) => {
    const copyOfAnswers = answers ? { ...answers } : {}
    const surveyUniqueID = survey.unique_id
    const sectionIndex = props.params.sectionId - 1

    const toDelete = []
    copyOfAnswers[surveyUniqueID].schema.sections[sectionIndex].questions
      .find((q) => q.id === props.question.id)
      .files.forEach((f, i) => {
        if (f.unique_id === deletedFile.unique_id) toDelete.push(i)
      })
    toDelete.forEach((index) => {
      copyOfAnswers[surveyUniqueID].schema.sections[sectionIndex].questions
        .find((q) => q.id === props.question.id)
        .files.splice(index, 1)
    })

    localStorage.setItem("answers", JSON.stringify(copyOfAnswers))
    setAnswers(copyOfAnswers)
    setFiles(
      copyOfAnswers[surveyUniqueID].schema.sections[
        sectionIndex
      ].questions.find((q) => q.id === props.question.id).files
    )
  }

  useEffect(() => {
    if (props.question.display_conditions) {
      const meetsDeps = evaluateQuestionDisplayConditions(
        props.question,
        answers,
        survey,
        assessment?.subject.species || captureGroup?.subject.species,
        props.params.sectionId - 1
      )
      if (!meetsDeps) removeFromAnswers()
      setQuestionMeetsDeps(meetsDeps)
    } else if (!questionMeetsDeps) {
      setQuestionMeetsDeps(true)
    }
  }, [answers])

  const handleQuestionClick = () => {
    if (answered) {
      setCollapsed((c) => !c)
    }
  }

  const handleClick = () => setClamped(!clamped)

  return (
    <>
      {questionMeetsDeps && props.question.type !== "blank" && (
        <div
          className={`p-3 border-b-2 ${
            answered
              ? "border-green-500 bg-green-50 md:bg-transparent md:border-2"
              : "border-gray-500 md:border"
          }  text-center md:rounded-lg md:mb-4`}
        >
          {files?.length && collapsed ? (
            <FaFile class="float-right"></FaFile>
          ) : null}
          <div
            onClick={handleQuestionClick}
            className={`font-semibold text-gray-400 ${
              answered && "cursor-pointer"
            }`}
          >
            Question {props.question_number}
          </div>
          <div
            onClick={handleQuestionClick}
            className={`font-semibold  text-lg text-gray-800 ${
              answered && "cursor-pointer"
            }`}
          >
            <TranslationWrapper
              translations={survey.survey_schema_translations}
            >
              {props.question.question}
            </TranslationWrapper>
            {!props.question.required && " (Optional)"}
          </div>
          {
            <div className={!answered || !collapsed ? "" : "hidden"}>
              {props.question.description && (
                <div
                  className={`text-gray-500 text-sm ${
                    clamped && "line-clamp-3"
                  }`}
                >
                  <TranslationWrapper
                    translations={survey.survey_schema_translations}
                  >
                    {props.question.description}
                  </TranslationWrapper>
                </div>
              )}
              {props.question.description?.length > 200 ? (
                <button onClick={handleClick}>
                  Read {clamped ? "more" : "less"}
                </button>
              ) : null}

              <div className="p-2 pt-2 mb-1">
                {QuestionTag ? (
                  <QuestionTag
                    survey={survey}
                    key={props.question.id}
                    question={props.question}
                    answer={getFromAnswers()}
                    getFromAnswers={getFromAnswers}
                    saveToAnswers={saveToAnswers}
                    removeFromAnswers={removeFromAnswers}
                    comment={getComment()}
                    saveComment={saveComment}
                    conformance={getConformance()}
                    saveConformance={saveConformance}
                    params={props.params}
                  />
                ) : (
                  <div className="text-red-600">Unmatched question type.</div>
                )}
              </div>
              {props.question.allow_file_upload && (
                <Upload
                  answers={answers}
                  survey={survey.unique_id}
                  section={props.params.sectionId - 1}
                  question={props.index}
                  files={files}
                  onDeleteFile={onDeleteFile}
                  onChangeFiles={onChangeFiles}
                />
              )}
            </div>
          }
        </div>
      )}
      {questionMeetsDeps && props.question.type === "blank" && (
        <div
          key={props.question.id}
          className={`px-2 py-8 text-center border-b-2 border-gray-500 md:mb-4 md:border-0`}
        >
          <div className="font-semibold text-xl text-gray-800">
            <TranslationWrapper
              translations={survey.survey_schema_translations}
            >
              {props.question.question}
            </TranslationWrapper>
          </div>
          {props.question.description && (
            <div className="text-gray-500 text-sm">
              <TranslationWrapper
                translations={survey.survey_schema_translations}
              >
                {props.question.description}
              </TranslationWrapper>
            </div>
          )}
        </div>
      )}
    </>
  )
}
