import { memo } from "react"
import { useStoreValue } from "react-context-hook"
import { translateSurveyText } from "../../utils/translate"

const TranslationWrapper = memo(({ translations, children }) => {
  const lang = useStoreValue("language", null)
  return translateSurveyText(lang, children, translations) || children || null
})

export default TranslationWrapper
