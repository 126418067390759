import React from "react"
import { AccountIcon } from "./accountIcon"
import { RefreshIcon } from "./refreshIcon"
import { BackIcon } from "./backIcon"

export const NavBar = (props) => {
  return (
    <div className="bg-nav pl-5 pr-7 flex text-center font-semibold text-lg text-gray-100">
      <div className="w-8 align-middle">
        {props.iconLeft === "refresh" && <RefreshIcon />}
        {props.iconLeft === "back" && <BackIcon route={props.route} />}
      </div>
      <div className="w-full align-middle p-2 pl-14">
        <span>{props.text}</span>
      </div>
      <div className="w-12 align-middle m-auto text-sm">
        v{process.env.REACT_APP_VERSION}
      </div>
      <div className="w-8 align-middle">
        {props.iconRight === "account" && <AccountIcon />}
      </div>
    </div>
  )
}
