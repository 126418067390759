import "./DataLoadingContainer.css"

const DataLoadingContainer = ({ loading = true, children }) => {
  return loading ? (
    <div className="absolute top-0 h-screen w-full text-primary ">
      <div className="flex w-full h-full just items-center">
        <div className="loader"></div>
      </div>
    </div>
  ) : (
    <>{children}</>
  )
}

export default DataLoadingContainer
