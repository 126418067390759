import { useEffect, useRef, useState } from "react"

export const SignatureCanvas = ({ onSave }) => {
  const [mouseDown, setMouseDown] = useState(false)
  const [touchDown, setTouchDown] = useState(false)

  const canvas = useRef()

  useEffect(() => {
    const handleResize = () => {
      canvas.current.width = canvas.current.offsetWidth
      canvas.current.height = canvas.current.offsetHeight // + window.scrollY;
    }

    handleResize()
    // window.addEventListener("resize", handleResize)
    // return () => {
    //   window.removeEventListener("resize", handleResize)
    // }
  }, [])

  // ** TOUCH
  const handleTouchDown = (e) => {
    const rect = canvas.current.getBoundingClientRect()
    var x = e.touches[0].clientX - rect.left
    var y = e.touches[0].clientY - rect.top
    const context = canvas.current.getContext("2d")
    context.beginPath()
    context.moveTo(x, y)
    setTouchDown(true)
  }

  const handleTouchMove = (e) => {
    const rect = canvas.current.getBoundingClientRect()
    var x = e.touches[0].clientX - rect.left
    var y = e.touches[0].clientY - rect.top
    const context = canvas.current.getContext("2d")
    if (touchDown) context.lineTo(x, y)
    else context.moveTo(x, y)
    context.stroke()
  }

  const handleTouchUp = () => {
    setTouchDown(false)
    handleSave()
  }

  // ** MOUSE
  const handleMouseDown = (e) => {
    const rect = canvas.current.getBoundingClientRect()
    var x = e.clientX - rect.left
    var y = e.clientY - rect.top
    const context = canvas.current.getContext("2d")
    context.lineWidth = 1
    context.beginPath()
    context.moveTo(x, y)
    setMouseDown(true)
  }

  const handleMouseMove = (e) => {
    const rect = canvas.current.getBoundingClientRect()
    var x = e.clientX - rect.left
    var y = e.clientY - rect.top
    const context = canvas.current.getContext("2d")
    context.lineWidth = 1

    if (mouseDown) context.lineTo(x, y)
    else context.moveTo(x, y)
    context.stroke()
  }

  const handleMouseUp = (save = true) => {
    const context = canvas.current.getContext("2d")
    context.beginPath() // This is needed for clearing to work with last stroke when mousing
    if (save || mouseDown) handleSave()
    setMouseDown(false)
  }

  // ** CLEAR AND SAVE
  const handleClear = () => {
    const context = canvas.current.getContext("2d")
    context.closePath()
    context.clearRect(0, 0, canvas.current.width, canvas.current.height)
    onSave(null)
  }

  const handleSave = () => {
    const canvas2 = document.createElement("canvas"),
      context2 = canvas2.getContext("2d")
    context2.lineWidth = 1
    const ratio = window.screen.width / window.screen.height
    canvas2.width = (canvas.current.width * 500) / canvas.current.height
    canvas2.height = 500
    context2.drawImage(canvas.current, 0, 0, canvas2.width, canvas2.height)
    const new_canvas_data_img = canvas2.toDataURL("image/png")
    onSave(new_canvas_data_img)
  }

  return (
    <>
      <canvas
        ref={canvas}
        className="bg-white border-2 border-gray-400 mx-auto  w-full h-52 rounded-lg"
        style={{ touchAction: "none" }}
        onTouchMoveCapture={handleTouchMove}
        onTouchStartCapture={handleTouchDown}
        onTouchEndCapture={handleTouchUp}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={() => handleMouseUp(false)}
      />
      <div className="flex">
        <button
          className="-mt-10 z-10 ml-auto rounded-lg p-2 text-md font-semibold text-gray-400"
          onClick={handleClear}
        >
          Clear
        </button>
      </div>
    </>
  )
}
