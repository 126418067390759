import React from "react"
import { useStoreValue } from "react-context-hook"

export const Header = () => {
  const branding = useStoreValue("branding", null)
  return (
    <div className="p-5 flex">
      <img
        src="/assets/img/fai-logo.png"
        alt=""
        className="h-24 max-h-12 md:max-h-24"
      />
      <img
        src={branding?.images.logo.large.replace("/public", "")}
        className="h-24 max-h-12 md:max-h-24 ml-auto"
      />
    </div>
  )
}
