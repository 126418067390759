import { useLiveQuery } from "dexie-react-hooks"
import { nanoid } from "nanoid"
import React, { useState } from "react"
import { FaTrash, FaUpload, FaCamera } from "react-icons/fa"
import { Capture } from "."
import { formatBytes } from "../../../utils/fileFormat"
import { db, addItemToDb, removeItemFromDb } from "../../../utils/indexedDb"

export const Upload = ({
  answers,
  survey,
  section,
  question,
  files,
  onDeleteFile,
  onChangeFiles,
}) => {
  const [camera, setCamera] = useState(false)
  const [duplicationError, setDupplicationError] = useState(false)
  const [oversizedFiles, setOversizedFiles] = useState([])
  const allFiles = useLiveQuery(() => db.items.toArray(), [])

  const checkDuplicateName = (name) => allFiles.some((f) => f.name === name)

  const handleFileSelect = (e) => {
    // save file to local storage
    files = files || []
    const newFiles = [...files]
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files.item(i)
      if (file.size > 10 * 1024 * 1024) {
        if (!oversizedFiles.includes(file.name))
          setOversizedFiles((curr) => [...curr, file.name])
        continue
      }

      let file_name = file.name
      if (checkDuplicateName(file_name)) {
        let i = 1
        let new_name = `${file_name.slice(
          0,
          file_name.lastIndexOf(".")
        )}-${i}${file_name.slice(file_name.lastIndexOf("."))}`
        while (checkDuplicateName(new_name) && i < 100) {
          i++
          new_name = `${file_name.slice(
            0,
            file_name.lastIndexOf(".")
          )}-${i}${file_name.slice(file_name.lastIndexOf("."))}`
        }

        if (checkDuplicateName(new_name)) {
          setDupplicationError(true)
          setTimeout(() => setDupplicationError(false), 3000)
          continue
        } else {
          file_name = new_name
        }
      }
      const unique_id = nanoid(32)
      addItemToDb(
        {
          name: file_name,
          size: file.size,
          type: file.type,
          unique_id,
          data: file,
        },
        survey,
        section,
        question
      )
        .then((res) => {
          newFiles.push({
            name: file_name,
            size: file.size,
            type: file.type,
            unique_id,
          })
          onChangeFiles(newFiles)
        })
        .catch((err) => {
          setDupplicationError(true)
          setTimeout(() => setDupplicationError(false), 3000)
        })
    }
  }

  const handleFileDelete = (file) => {
    removeItemFromDb(file.unique_id)
    onDeleteFile(file)
  }

  const handlePhotoSelect = async (dataString) => {
    const name = Math.floor(new Date().getTime() / 100)
    const size = Math.floor(
      dataString.length * (3 / 4) - (dataString[-2] === "=" ? 2 : 1)
    )
    const type = "image/png"
    const unique_id = nanoid(32)
    addItemToDb(
      {
        name,
        size,
        type,
        unique_id,
        data: dataString,
      },
      survey,
      section,
      question
    )
      .then((res) => {
        files = files || []
        const newFiles = [...files, { name, size, type, unique_id }]
        onChangeFiles(newFiles)
      })
      .catch((err) => {
        setDupplicationError(true)
        setTimeout(() => setDupplicationError(false), 3000)
      })
  }

  const handleOversizedErrorClick = (name) => {
    setOversizedFiles((curr) => {
      return curr.filter((n) => n != name)
    })
  }

  return (
    <>
      <div className="text-center align-middle flex">
        <label className="inline rounded-lg w-24 p-2 ml-auto text-white cursor-pointer font-semibold bg-primary">
          Upload <FaUpload className="inline-block pb-0.5 m-auto h-full" />
          <input type="file" onChange={handleFileSelect} hidden multiple />
        </label>
        <label className="inline rounded-lg w-10 p-2 mr-auto ml-1  text-white cursor-pointer font-semibold bg-primary">
          <FaCamera className="inline-block pb-0.5 m-auto h-full" />
          <input
            type="file"
            onChange={handleFileSelect}
            accept="image/*,video/*"
            capture="environment"
            hidden
          />
        </label>
        {/* <button onClick={() => {setCamera(true)}} className="inline rounded-lg w-10 p-2 mr-auto ml-1  text-white cursor-pointer font-semibold bg-primary" >
          <FaCamera className="m-auto" />
        </button> */}
      </div>
      {camera && (
        <Capture
          externalButton={true}
          setExternalState={setCamera}
          handlePhotoSelect={handlePhotoSelect}
        />
      )}
      {Array.isArray(files) &&
        files?.map((f) => (
          <div
            key={f.name}
            className="rounded-xl p-2 my-1 border-2 border-green-600 flex align-middle"
          >
            <p className="my-auto button break-all mr-2">
              {f.name} — ({formatBytes(f.size)})
            </p>
            <button
              onClick={() => handleFileDelete(f)}
              className="rounded-md bg-red-600 active:bg-red-800 p-2 ml-auto mr-0"
            >
              <FaTrash color="white" />
            </button>
          </div>
        ))}
      {duplicationError && (
        <div className="rounded-xl transition-all duration-300 text-left p-2 my-1 border-2 border-green-600 bg-red-300 flex align-middle">
          <p className="my-auto button">
            Failed to upload file. Make sure you are uploading files with unique
            names.
          </p>
        </div>
      )}
      {oversizedFiles?.map((name) => (
        <div
          key={name}
          className="rounded-xl transition-all duration-300 text-left p-2 my-1 border-2 border-green-600 bg-red-300"
        >
          <p className="my-auto button">
            {name} is too large, make sure you're files are below 10 MB.
          </p>
          <p
            onClick={() => handleOversizedErrorClick(name)}
            className="text-gray-600 cursor-pointer text-right m-auto font-medium"
          >
            Dismiss
          </p>
        </div>
      ))}
    </>
  )
}
